// .banner2 .banner2-txt, .banner2 .item > img {height: 600px;}

.banner2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 812px;
}
.banner2 .col-lg-4 {
  padding-right: 0;
}
.banner2 .col-lg-8 {
  padding-left: 0;
}
.banner2 .container-fluid {
  padding: 0;
}
.banner2 .item > img {
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.banner2 .banner2-txt,
.banner2 .item > img {
  height: 812px;
}
.banner2-txt {
  position: relative;
  padding: 0 0 0 20em;
  width: 750px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgb(250, 201, 22);
  flex-direction: column;
  margin: 0;
}
.banner2-txt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  background-image: url("../../../../assets/images/banner-cover.jpg");
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  z-index: 2;
}
.banner2-txt::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  background-color: rgb(250, 201, 22);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  mix-blend-mode: multiply;
  z-index: 2;
}
.banner2-txt h2 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 900;
  margin: 0 0 6px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: uppercase;
  position: relative;
  z-index: 4;
  transition: all 0.2s ease;
}
.banner2-txt p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  z-index: 4;
  border-radius: 3px;
  letter-spacing: 2px;
  margin: 0 0 1rem;
}
.slider-controls {
  margin: 1.5em 0 0;
  display: flex;
  z-index: 4;
  width: 90px;
  justify-content: space-between;
}
.slider-controls img {
  width: 40px;
}
.slider-controls img:hover {
  opacity: 0.7;
}

#bannerBackground2 {
  background-image: url("../../../../assets/images/site/HotelBannerBG.png");
  background-size: cover;
  height: 600px;
  max-height: 100%;
  width: 100%;
}

#hotelbannerheading {
  font-size: 23px;
  color: #ffffff;
  font-weight: 600;
  font-family: Poppins;
  z-index: 10; /* Ensure it appears above other elements */
  text-transform: none;
}

#hotelbannerheading::before {
  content: "";
  position: absolute;
  top: -25px;
  left: 3%;
  transform: translateX(-50%);
  background-image: url("../../../../assets/images/site/hotelbannerL1.png");
  background-size: 26px;
  width: 26px;
  height: 21px;
}

#hotelbannerheading2::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 58%;
  transform: translateX(-50%);
  background-image: url("../../../../assets/images/site/curve\ white\ 1.png");
  background-size: 35px;
  width: 35px;
  height: 12px;
}

#hotelbannerheading2::after {
  content: "";
  position: absolute;
  top: -30px;
  left: 95%;
  transform: translateX(-50%);
  background-image: url("../../../../assets/images/site/shape-02\ 1.png");
  background-size: 60px;
  width: 60px;
  height: 47px;
}

@media (min-width: 1200px) {
  /* Adjust the min-width value as needed to target your definition of large screens */
  .static-image-container {
    margin-right: -100px !important;
    margin-left: 100px !important;
  }
  #hotelbannerheading {
    margin-left: 40px;
    margin-top: 50px !important;
  }
  #hotelbannerheading2 {
    margin-left: 40px;
    font-size: 41px;
    font-family: Poppins;
  }
  .video-container3 {
    margin-left: 60px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px)  {
	#hotelbannerheading,
	#hotelbannerheading2 {
		margin-left: 200px !important;
	}
	#hotelbannerheading2::after {
		content: "";
		position: absolute;
		top: -25px;
		left: 88%;
		transform: translateX(-50%);
		background-image: url("../../../../assets/images/site/shape-02\ 1.png");
		background-size: 50px;
		width: 50px;
		height: 40px;
	  }
}



// Diplay none on mobile screens
@media (max-width: 768px) {
  .static-image-container {
    text-align: center; /* Keep it centered on mobile */
    margin-top: 1rem; /* Adjust margin for mobile */
  }

  #hotelImage img {
    max-width: 100%; /* Make image responsive */
    height: 450px; /* Maintain aspect ratio */
  }

  #bannerBackground {
    background-size: cover; /* Adjusts the image to fit within the container */
    background-position: top; /* Aligns the image to the top */
    height: 400px; /* Reduce the height for a better fit on mobile */
  }

  #hotelbannerheading {
    margin-top: 80px;
	margin-left: -10px;
  }

  #hotelbannerheading2 {
	margin-left: -10px;
  }
  #underlinebanner img {
	// margin-left: 100px !important;
	width: 250px;
  }

  #hotelImage img {
	height: 240px !important;
	margin-top: -65px !important;
  }

  #hotelbannerheading2::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 53%;
    background-size: 27px;
    width: 27px;
    height: 9px;
  }

  #hotelbannerheading2::after {
    content: "";
    top: -12px;
    left: 86%;
    background-size: 30px;
    width: 30px;
    height: 23px;
  }
}

@media (min-width: 768px) {
  .maskot-footer {
    position: absolute;
    left: 22px;
    top: -17px;
    width: 150px;
    filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
    z-index: 20;
  }
  .banner2-txt {
    padding-bottom: 40px !important;
  }
}

// @media (min-width: 1200px) {
//   .heading {
//     font-size: 2.5rem;
//   }
//   .footer-menu li a {
//     font-size: 1.3rem;
//   }
// }


@media (min-width: 992px) and (max-width: 1199px) {
	#hotelbannerheading {
		margin-top: 10px;
	}
	#hotelbannerheading2::after {
		content: "";
		top: -12px;
		left: 86%;
		background-size: 30px;
		width: 30px;
		height: 23px;
	  }
	  #hotelbannerheading2::before {
		content: "";
		position: absolute;
		top: -8px;
		left: 53%;
		background-size: 27px;
		width: 27px;
		height: 9px;
	  }

	  #nightfirst {
		margin-left: -50px !important;
	  }

}

@media (max-width: 1886px) {
  .banner2-txt {
    padding: 0 0 0 3em;
    width: 118%;
  }
  /* .custom-carousel-wrapper .carousel-indicators {left: 0;transform: translateX(-130px);} */
}
@media (max-width: 1400px) {
  // .banner2-txt p { letter-spacing: 2px;}
  /* .custom-carousel-wrapper .carousel-indicators {left: 0;transform: translateX(-90px);} */
  // .keypoints .container, .profile .container { width: inherit;}
  .top-deals .util-carousel {
    margin: 1.5em 0 0;
  }
  // .te .heading, .top-deals .heading, .testimonials .heading { margin-left: 15px;}
}
@media (max-width: 1199px) {
  .join-blocks-img img {
    height: 100%;
    object-fit: cover;
  }
  .footer-menu .heading {
    font-size: 2em;
  }
  .top-deals .util-carousel {
    margin: 1.5em 0 0;
  }
  .take-me-there .join-blocks h4 {
    font-size: 16px;
  }
  #cssmenu {
    transform: translateX(70px);
  }
  #cssmenu > ul > li > a {
    padding: 0 20px;
  }
  .landing-page #cssmenu > ul > li > a {
    padding: 0 45px;
  }
  .banner2-txt p {
    letter-spacing: 1px;
  }
  .slider-controls {
    margin: 0.5em 0 0;
  }
  .experiences::after {
    width: 55%;
    right: -33%;
  }
  #carousel div.selected {
    width: 600px;
  }
}

@media (max-width: 991px) {
  .join-blocks a {
    font-size: 1em;
  }
  #cssmenu {
    transform: translateX(0);
  }
  #cssmenu #menu-button:before {
    top: 17px;
    right: 17px;
  }
  #cssmenu #menu-button:after {
    top: 23px;
    right: 17px;
  }
  #cssmenu ul {
    margin: 0;
    transform: translateY(104px);
  }
  #cssmenu ul li {
    border-top: 0;
  }
  #cssmenu > ul > li > a,
  #cssmenu > ul > li:last-child > a {
    padding: 15px 20px;
    background-color: #000;
  }
  #cssmenu #menu-button:after {
    border-width: 2px;
    right: auto;
    left: 17px;
    top: 16px;
    border-color: #fff;
    transition: none;
  }
  #cssmenu #menu-button:before {
    height: 2px;
    right: auto;
    left: 17px;
    top: 10px;
    background-color: #fff;
    transition: none;
  }
  #cssmenu #menu-button.menu-opened:before {
    top: 85px;
    background: #fff;
  }
  #cssmenu #menu-button.menu-opened:after {
    top: 85px;
    background: #fff;
    height: 2px;
  }
  .landing-page #cssmenu ul {
    display: block;
  }
  .landing-page #cssmenu ul {
    transform: translateY(10px);
  }
  .landing-page #cssmenu > ul > li > a,
  .landing-page #cssmenu > ul > li:last-child > a {
    padding: 15px 20px;
  }
  .landing-page #cssmenu #menu-button.menu-opened:before {
    top: 10px;
  }
  .landing-page #cssmenu #menu-button.menu-opened:after {
    top: 10px;
  }
  .landing-image > img {
    margin: 0 auto;
  }
  .landing .large {
    margin: 6em auto 0;
  }
  .experiences .join-blocks h4 {
    font-size: 18px;
  }
  .maskot {
    top: 27%;
  }
  .banner2-txt {
    width: 100%;
  }
  .banner2-txt::before,
  .banner2-txt::after {
    width: 390px;
  }
  .banner2-txt h2 {
    font-size: 2em !important;
  }
  .banner2-txt h2,
  .banner2-txt p {
    white-space: nowrap;
  }
  .banner2-txt h2 {
    font-size: 2em !important;
  }
  .profile .col-lg-9,
  .profile .col-lg-3 {
    width: 100%;
  }
  .reservation-form .persons {
    width: 100%;
  }
  .reservation-form {
    height: auto;
  }
  /* .custom-carousel-wrapper .carousel-indicators {transform: translateX(-55px);} */
  .experiences::after {
    right: -53%;
  }
  #carousel div.selected img {
    width: 600px;
  }
  .buttons button:first-child {
    left: 12%;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0.3em;
    border-radius: 6px;
  }
  .buttons button:last-child {
    right: 12%;
    z-index: 21;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0.3em;
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  .slider-controls {
    margin: 0;
    display: none;
  }
  .banner2-txt {
    text-align: center;
    background-color: #fac532;
    height: auto !important;
    padding: 1.5em 0;
    align-items: center;
    margin: 0 auto;
    z-index: 1;
  }
  .banner2-txt h2 {
    font-size: 2em;
    letter-spacing: 1px;
    margin: 0;
  }
  .banner2-txt p {
    font-size: 26px;
    margin: 0 0 0.4em;
  }
  .banner2-txt h2,
  .banner2-txt p {
    white-space: normal;
  }
  .banner2-txt::before,
  .banner2-txt::after {
    width: 100%;
  }
  .banner2 .item > img {
    height: 320px !important;
  }
  .book-now {
    padding: 0.5em 1em;
    font-size: 16px;
    letter-spacing: 1px;
    color: #ffffff;
  }
  .custom-carousel-wrapper .carousel-control {
    display: flex;
  }
  .wavey.desk {
    display: none;
  }
  .wavey.mob {
    margin: auto;
    display: block;
    right: 0;
    height: auto;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  .custom-carousel-wrapper .carousel-control {
    transform: translateY(-50%);
    top: 50%;
  }
  .custom-carousel-wrapper .left.carousel-control .img-responsive {
    left: 30px;
    width: 30px;
  }
  .custom-carousel-wrapper .right.carousel-control .img-responsive {
    right: 30px;
    width: 30px;
  }
  .top-experiences .util-theme-default .util-prev img,
  .top-experiences .util-theme-default .util-next img {
    width: 20px;
  }
  /* .keypoints .keypoints-container { margin: 0 0 1.5em;} */
  /* .keypoints-bg .col-xs-6:nth-child(3) .keypoints-container, .keypoints-bg .col-xs-6:last-child .keypoints-container { margin: 0;} */
  .keypoints .keypoints-container::before {
    left: 50%;
    top: 2px;
    width: 40px;
    height: 22px;
    transform: translateX(-50%);
  }
  footer {
    padding: 40px 0 40px;
  }
  footer .footer-logo img {
    max-width: 250px;
  }
  .maskot {
    width: 110px;
    top: auto;
    bottom: 0;
  }
  .social-media-links {
    transform: translateY(0);
  }
  .experiences::after {
    width: 65%;
    right: -33%;
    transform: skewX(0);
  }
  .top-experiences .util-theme-default .util-navigation {
    position: static;
    margin: 1.5em 0 0;
  }
  .pattern-experiences::before {
    width: 100%;
  }
  .highlights span {
    width: 100%;
  }
  #carousel {
    height: 236px;
  }
  #carousel div.selected {
    width: 60%;
  }
  #carousel div.prev,
  #carousel div.next {
    transform: translateY(15px);
  }
  #carousel div.selected img {
    width: 100%;
    object-fit: cover;
  }
  #carousel div.prev img,
  #carousel div.next img {
    width: 300px;
    object-fit: cover;
  }
  .banner2 .col-lg-4,
  .banner2 .col-lg-8 {
    padding-right: 15px;
    padding-left: 15px;
  }
  .maskot-header {
    width: 110px;
  }
  .maskot-footer {
    width: 100px;
  }
  .registration-form .highlights span,
  .registration-form .highlights span:nth-child(even) {
    width: 100%;
    margin: 0 0 1em;
  }
  .highlights span > div {
    width: calc(100% - 56px);
  }
  .profile {
    padding: 7em 0 8em;
  }
  .registration-form {
    margin: 0;
    padding: 8em 0;
  }
  .registration-form .card-slider {
    flex-direction: column;
  }
  .inputfile + label {
    width: 100%;
    margin: 1.5em 0 0;
  }
}

.video-container3 {
  width: 90%; /* Container scales based on screen size */
  max-width: 600px; /* Width of the video container */
  height: auto;
  border-radius: 20px !important;
  //   margin-top: 10px !important;
  z-index: 10;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-image2 {
  position: absolute;
  bottom: -22px; /* Adjust to control the overlap on the video container */
  left: 47%;
  transform: translateX(-50%);
  width: 60%; /* Set to a percentage so it scales with the container */
  max-width: 300px;
  height: auto;
  z-index: 10;
}

.overlay-text2 {
  position: absolute;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  left: -3%;
  top: 100%;
  transform: translateY(-50%);
  z-index: 10;
}

// .centered-image2 {
//     position: absolute; /* Stay centered */
//     top: 92%; /* Center vertically on the gradient line */
//     left: 47%; /* Center horizontally */
//     transform: translate(-50%, -50%); /* Adjust for true center alignment */
//     // height: 55px; /* Adjust height as necessary */
//     object-fit: cover; /* Ensures the image covers the area without distortion */
//     z-index: 2; /* Make sure the image is above the contact form */
// 	width: 50%;
// 	height: 7%;

//     @media (max-width: 768px) {
//       height: 45px; /* Adjust height for smaller screens */
//     }

//     @media (max-width: 480px) {
//       height: 35px; /* Further adjust for very small screens */
//     }
//   }
