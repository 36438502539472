html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Figtree";
  font-style: normal;
  background-color: #111;
}

.header-section a,
.header-section button {
  display: inline-block;
  color: #000;
  text-decoration: none;
  padding: 10px;
  line-height: normal;
}

.header-section .logout {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  background-color: var(--theme-color1);
  border-radius: 30px;
  text-transform: uppercase;
  padding: 8px 20px;
  letter-spacing: 1px;
}

.header-section a:hover,
.header-section a:focus {
  color: #fff !important;
}

.button-new {
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 2px !important;
  width: 140px !important;
}

.social a,
.social button {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  line-height: normal;
}

.social .logout {
  font-size: 15px;
  font-weight: 500;
  background-color: var(--theme-color1);
  border-radius: 30px;
  text-transform: uppercase;
  padding: 8px 20px;
  letter-spacing: 1px;
}

.social .logout:hover {
  color: var(--black);
  background-color: var(--theme-color2);
}

.social a:hover,
.social a:focus {
  color: var(--theme-color1);
}

.social a>img {
  width: 25px;
}

#cssmenu {
  transform: translateX(40px);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: 'Arial Rounded MT Std';
  /* Replace with the name of your font */
  src:
    url('../../assets/fonts/Arial\ Rounded\ MT\ Std\ Regular.otf') format('truetype');
  /* Add more formats if needed */

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fac532;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#1a1d1e, #666);
  border-radius: 30px;
}

.small-spacer {
  margin: 1em 0;
}

.spacer {
  margin: 3em 0 3.5em;
}

.header-menu {
  position: fixed;
  top: 0;
  z-index: 16;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #000000;
  padding: 1.3em 0;
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 180px;
  width: 100%;
}

.social a,
.social button {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  line-height: normal;
}

.social .logout {
  font-size: 15px;
  font-weight: 500;
  background-color: var(--theme-color1);
  border-radius: 30px;
  text-transform: uppercase;
  padding: 8px 20px;
  letter-spacing: 1px;
}

.social .logout:hover {
  color: var(--black);
  background-color: var(--theme-color2);
}

.social a:hover,
.social a:focus {
  color: var(--theme-color1);
}

.social a>img {
  width: 25px;
}

#cssmenu {
  transform: translateX(40px);
}

.banner {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.banner .col-lg-4 {
  padding-right: 0;
}

.banner .col-lg-8 {
  padding-left: 0;
}

.banner .container-fluid {
  padding: 0;
}

.banner .item>img {
  width: 100%;
  object-fit: cover;
  object-position: right;
}

.banner .banner-txt,
.banner .item>img {
  height: 377px;
}

.banner-txt {
  position: relative;
  padding: 0 0 0 20em;
  width: 750px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgb(250, 201, 22);
  flex-direction: column;
  margin: 0;
}

.banner-txt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  background-image: url("../images/banner-cover.jpg");
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  z-index: 2;
}

.banner-txt::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  background-color: rgb(250, 201, 22);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  mix-blend-mode: multiply;
  z-index: 2;
}

.banner-txt h2 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 900;
  margin: 0 0 6px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: uppercase;
  position: relative;
  z-index: 4;
  transition: all 0.2s ease;
}

.banner-txt p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  z-index: 4;
  border-radius: 3px;
  letter-spacing: 2px;
  margin: 0 0 1rem;
}

.slider-controls {
  margin: 1.5em 0 0;
  display: flex;
  z-index: 4;
  width: 90px;
  justify-content: space-between;
}

.slider-controls img {
  width: 40px;
}

.slider-controls img:hover {
  opacity: 0.7;
}

/* .custom-carousel-wrapper .carousel-indicators li { background-color: #545454;} */
/* .custom-carousel-wrapper .carousel-indicators { width: auto; bottom: 40px; left: 90px;} */
/* .custom-carousel-wrapper .carousel-control { z-index: 4; opacity: 1; position: absolute; transform: translateY(-40%); top: 60%; background: #fff; width : 35px; height : 35px; border-radius: 50%; align-items: center; justify-content: center; display:none; } */
/* .custom-carousel-wrapper .carousel-control.left, .custom-carousel-wrapper .carousel-control.right {background: #fff;} */
/* .custom-carousel-wrapper .carousel-control.left { left: 25px;} */
/* .custom-carousel-wrapper .carousel-control.right { right: 25px;} */
/* .custom-carousel-wrapper .left.carousel-control > img, .custom-carousel-wrapper .right.carousel-control > img { width : 20px;} */

.book-now {
  font-size: 22px;
  letter-spacing: 2px;
  z-index: 4;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #000;
  border-radius: 1rem;
  padding: 0.6em 1.4em;
}

.book-now:hover,
.book-now:focus {
  text-decoration: none;
  color: rgb(250, 180, 22);
}

.heading {
  position: relative;
  color: #ddb12e;
  font-weight: 700;
  font-size: calc(1.375rem + 1.5vw);
  text-transform: uppercase;
  text-align: center;
}

.heading::before {
  content: "";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("../images/icons/wave.png");
  background-size: 68px;
  width: 68px;
  height: 23px;
}

// .top-deals .heading, .take-me-there .heading { font-size: 2.6em;}

.keypoints {
  width: 100%;
  background-color: #000;
}

// .keypoints .container { width: 1300px; }
/* .keypoints .keypoints-container { position: relative;display: flex; align-items: center; justify-content: start;} */
.keypoints .keypoints-container .image-container {
  height: 90px;
  width: 90px;
  background-color: #b68e15;
}

.keypoints .keypoints-container img {
  width: 57px;
  z-index: 1;
}

.keypoints .keypoints-container h4 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}

.top-experiences {
  width: 100%;
  position: relative;
}

.top-experiences .util-carousel {
  margin: 1.5em 0 0;
}

.top-experiences .util-carousel .util-item .item {
  margin: 0 0.75em;
}

.top-experiences .util-carousel .util-item:last-child .item {
  margin: 0 0 0 0.75em;
}

.top-experiences .util-theme-default .util-navigation {
  position: absolute;
  top: -70px;
  right: 0;
  z-index: 3;
}

.top-experiences .util-theme-default .util-prev {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
}

.top-experiences .util-theme-default .util-next {
  background-color: #ddb12e;
  color: #000;
  border-radius: 10px;
}

.util-theme-default .util-prev,
.util-theme-default .util-next {
  font-size: 24px;
}

.join-blocks {
  height: 382px;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #333232;
  border-radius: 16px;
  padding: 8px 8px 20px;
}

.join-blocks-import {
  width: 100%;
  position: relative;
  // overflow: hidden;
  background-color: #333232;
  border-radius: 5px;
  padding: 8px;
}

.join-blocks-img {
  position: relative;
  width: 100%;
  padding: 1em 0 0;
  border-radius: 13px;
  overflow: hidden;
  height: 224px;
  margin: 0 0 1.5em;
}

.join-blocks-img img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.join-blocks h4 {
  padding: 0 10px;
  text-align: left;
  color: #fff;
  font-size: 17px;
  margin: 15px 0 0;
  font-weight: 400;
  line-height: 1.4em;
}

.join-blocks-new h4 {
  margin: 5px 0 0;
}

.stars {
  padding: 0 10px;
  display: flex;
  color: #fff;
  align-items: baseline;
  margin: 0.8em 0 0.5em;
}

.stars i {
  color: #ff9c00;
  margin: 0 3px 0 0;
  font-size: 18px;
}

.stars i:last-child {
  margin: 0 10px 0 0;
}

.price {
  padding: 0 10px;
  display: flex;
  color: #fff;
  align-items: baseline;
  font-size: 16px;
}

.price del {
  color: #fff;
  margin: 0 5px;
}

.price ins {
  color: #fff;
  text-decoration: none;
}

.top-deals {
  padding: 0;
}

.top-deals .util-carousel {
  margin: 1.5em 0 0;
}

.top-deals .util-carousel .util-wrapper-holder {
  padding: 1em 0 0;
}

.top-deals .util-theme-default .util-navigation {
  top: -60px;
  z-index: 3;
}

.take-me-there {
  padding: 5em 1em;
  background-color: #000;
}

.take-me-there .util-theme-default .util-navigation {
  top: -60px;
  z-index: 3;
  right: 0;
}

.take-me-there .join-blocks {
  height: auto;
  padding: 0;
  background-color: #000;
}

.take-me-there .join-blocks h4 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.take-me-there .join-blocks-img {
  height: 382px;
}

.experiences {
  overflow: hidden;
}

.experiences::before,
.experiences::after {
  top: 0;
  height: 100%;
  content: "";
  position: absolute;
  width: 400px;
  background-color: #ddb12e;
  border-radius: 50%;
}

.experiences::before {
  width: 40%;
  left: -20%;
}

.experiences::after {
  width: 35%;
  right: -13%;
  z-index: 0;
  transform: skewX(-20deg);
}

.experiences .join-blocks {
  height: auto;
  z-index: 1;
}

.experiences .join-blocks-img {
  height: 251px;
  border-radius: 15px;
  margin: 0;
}

.experiences .join-blocks h4 {
  position: static;
  transform: none;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  color: #fff;
  margin: 0;
  padding: 0.7em;
}

/* .new-experiences { margin: 0 0 4em;} */
.new-experiences .join-blocks-img {
  height: 225px;
}

.new-experiences .util-carousel {
  margin: 1.5em 0 0;
}

.new-experiences .util-carousel .util-item:last-child .item {
  margin: 0 0.75em;
}

.new-experiences .price {
  margin: 1em 0;
}

.new-experiences .price ins {
  color: #ddb12e;
}

/* .testimonials { margin: 0 0 2em; padding: 3em 0;} */
.testimonials .util-carousel .util-wrapper-holder {
  padding: 1em 0 0;
}

.testimonials .util-theme-default .util-navigation {
  position: absolute;
  top: -70px;
  right: 0;
  z-index: 3;
}

.testimonials .join-blocks {
  min-height: 460px;
  height: auto;
  margin: -50px 0 0;
  text-align: center;
  padding: 3rem 1.5rem 2rem;
  background-color: #1d1d1d;
  border-radius: 15px;
}

.testimonials .join-blocks-img {
  z-index: 2;
  height: 94px;
  width: 94px;
  border-radius: 50%;
  margin: auto;
}

.testimonials .join-blocks h4 {
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 20px;
  margin: 25px 0 0;
}

.testimonials .join-blocks p {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

.testimonials .stars {
  justify-content: center;
}

.testimonials .stars i {
  margin: 0 3px;
}

footer {
  background: #000;
  padding: 5em 0;
  text-align: center;
  position: relative;
}

.footer-menu p {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  margin: 0 0 1.2em;
  position: relative;
  line-height: 1.6em;
}

.footer-menu ul {
  list-style: none;
  padding: 0;
}

.footer-menu li a {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: capitalize;
}

.footer-menu li .footer-custom-class {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
}

footer .footer-logo img {
  display: block;
  max-width: 373px;
  width: 100%;
  position: relative;
  margin: auto;
}

.social-media-links {
  display: flex;
  justify-content: center;
}

.footer-menu .icon {
  background-color: var(--theme-color1);
  height: 45px;
  width: 45px;
}

.footer-menu .icon i {
  font-size: 1.4rem;
  color: #000;
}

.footer-menu a:hover,
.footer-menu a:focus {
  color: #fff;
}

.custom-carousel-wrapper .carousel-fade .carousel-inner {
  background: #fff;
}

.custom-carousel-wrapper .carousel-fade .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  transition-timing-function: linear;
}

.custom-carousel-wrapper .carousel-fade .next.left,
.custom-carousel-wrapper .carousel-fade .prev.right,
.custom-carousel-wrapper .carousel-fade .item.active {
  opacity: 1;
}

.custom-carousel-wrapper .carousel-fade .active.left,
.custom-carousel-wrapper .carousel-fade .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

/* Safari Fix */
@media all and (transform-3d),
(-webkit-transform-3d) {

  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.next,
  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.prev,
  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.next.left,
  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.prev.right,
  .custom-carousel-wrapper .carousel-fade .carousel-inner>.item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.maskot-header {
  position: absolute;
  right: 15px;
  bottom: 2%;
  width: 150px;
  filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
  z-index: 20;
}

/* .profile { padding: 3em 0 8em; margin: 105px 0 0; border-top: 1px solid #555;} */
.profile .col-lg-9 {
  width: 60%;
}

.profile .col-lg-3 {
  width: 40%;
}

.heading.large {
  margin: 0.5em 0;
  font-size: 3.3em;
}

.breadcrum {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  flex-direction: column;
}

.breadcrum li {
  color: #fff;
  font-weight: 600;
  font-size: 2.4em;
}

.breadcrum li:last-child {
  font-weight: 400;
  font-size: 1.5em;
  color: #c4c4c4;
}

.additional-details {
  float: left;
  width: 100%;
}

.additional-details .heading {
  margin: 1em 0 0;
}

.additional-details p {
  margin: 1em 0 0;
  font-size: 18px;
}

.additional-details ul {
  margin: 1em 0 0;
  padding: 0 0 0 1.5em;
}

.additional-details ul li {
  font-size: 18px;
  line-height: 1.5em;
}

.highlights {
  display: flex;
  flex-wrap: wrap;
  margin: 2em 0 0;
}

.highlights span {
  font-size: 18px;
  width: 50%;
  display: flex;
  align-items: center;
  margin: 0 0 1em;
}

.highlights span>svg {
  width: 25px;
  margin: 0 10px 0 0;
  transform: none;
  position: static;
}

.highlights span>abbr {
  margin: 0 1em 0 0;
  background-color: #ddb12e;
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
}

.highlights span>div {
  width: calc(100% - 100px);
}

.highlights span h3 {
  margin-top: 0;
}

.highlights span p {
  color: #c4c4c4;
}

.review-stars {
  margin: 1em 0 0;
  display: flex;
  align-items: center;
}

.review-stars span {
  color: #000;
  font-size: 22px;
  margin: 0 0 0 20px;
}

.review-stars span.dull {
  color: #999;
  font-size: 16px;
}

.review-stars>svg {
  transform: none;
  position: static;
}

.reservation-form {
  position: relative;
  margin: 2em 0 0;
  background-color: #3d3d3d;
  border-radius: 8px;
  border: 3px solid #ddd;
  padding: 1.5em;
  box-shadow: 0 3px 14px 3px rgba(0, 0, 0, 0.1);
}

.reservation-form h2 {
  margin: 0 0 0.8em;
}

.reservation-form span {
  font-size: 14px;
  display: block;
  margin: 0.3em 0 0;
}

.reservation-form a.booking-btn {
  text-decoration: none;
  color: #fff;
  background-color: #ddb12e;
  display: block;
  border-radius: 30px;
  padding: 0.6em 1em;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin: 0.3em 0 0;
}

.reservation-form input.booking-btn {
  border: 0;
  display: block;
  color: #fff;
  background-color: #ddb12e;
  width: 100%;
  border-radius: 30px;
  padding: 0.6em 1em;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin: 0.3em 0 0;
}

.reservation-form svg {
  fill: #ddb12e;
  transform: none;
  position: static;
  width: 35px;
  margin: 0 5px 0 0;
}

.cancellation {
  color: #fff;
  margin: 1em 0 0;
  display: flex;
  align-items: start;
  font-size: 18px;
  text-align: left;
}

.ticket-booker {
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 1em 0;
  position: relative;
}

.reservation-form input[type="text"].times {
  width: 100%;
  margin: 0 0 0.5em;
  background-color: #eee;
}

.reservation-form .dates {
  padding-left: 50px;
}

.reservation-form input[type="text"].persons {
  padding: 15px 10px 15px 40px;
}

.booking-calendar {
  position: relative;
}

.ticket-booker i.fa-calendar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 18px;
  color: #ddb12e;
}

.person-group {
  position: relative;
  width: 100%;
  margin: 0 0 1em;
}

.ticket-booker i.fa-chevron-down {
  position: absolute;
  top: 18px;
  right: 18px;
  font-size: 15px;
  color: #fff;
}

.reservation-form .persons {
  width: 100%;
  text-align: left;
}

// .reservation-form input[type="date"]::-webkit-calendar-picker-indicator{
//     background-color: #ffffff;
//     padding: 5px;
//     cursor: pointer;
//     border-radius: 3px;
// }
div#ui-datepicker-div .ui-datepicker-prev.ui-corner-all {
  position: absolute;
  right: 15px;
  cursor: pointer;
  color: transparent;
}

div#ui-datepicker-div .ui-datepicker-next.ui-corner-all {
  position: absolute;
  left: 15px;
  cursor: pointer;
  color: transparent;
}

div#ui-datepicker-div .ui-datepicker-next.ui-corner-all span.ui-icon.ui-icon-circle-triangle-e::before {
  content: "\f104";
  font-family: "FontAwesome";
  font-weight: 900;
  color: #000;
  position: absolute;
  left: 0;
}

div#ui-datepicker-div .ui-datepicker-prev.ui-corner-all span.ui-icon.ui-icon-circle-triangle-w::before {
  content: "\f105";
  font-family: "FontAwesome";
  font-weight: 900;
  color: #000;
  position: absolute;
  right: 0;
}

div#ui-datepicker-div .ui-datepicker-next.ui-corner-all span.ui-icon.ui-icon-circle-triangle-e:hover::before,
div#ui-datepicker-div .ui-datepicker-prev.ui-corner-all span.ui-icon.ui-icon-circle-triangle-w:hover::before {
  color: #fac532;
}

.ui-datepicker-title {
  display: block;
  margin: 0 0 0.5em;
}

div#ui-datepicker-div {
  background: #fff;
  padding: 1em;
  border-radius: 8px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 7px 23px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 111 !important;
}

div#ui-datepicker-div table {
  width: 100%;
}

a.ui-state-default {
  color: #1a1d1e;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.ui-state-default:hover {
  background-color: #f2f2f2;
  text-decoration: none;
}

a.ui-state-default.ui-state-highlight.ui-state-active {
  background-color: #fac532;
  color: #1a1d1e;
  font-weight: 600;
}

.persons-description {
  z-index: 2;
  float: left;
  width: 100%;
  background-color: #3d3d3d;
}

.persons-description h3 {
  font-size: 20px;
  margin: 0.5em 0 1.5em;
  font-weight: 700;
}

.persons-description h4 {
  color: #fff;
  font-size: 17px;
  margin: 0;
}

.persons-description span {
  margin: 0.2em 0 0;
  font-size: 12px;
}

.person-group-details {
  position: relative;
  display: flex;
  margin: 0 0 1em;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border: 1px solid #ddd;
  padding: 15px 10px 15px 50px;
  border-radius: 30px;
  height: 55px;
}

.person-group-details i {
  position: absolute;
  left: 18px;
  top: 16px;
  color: #ddb12e;
  font-size: 18px;
}

/* Start: VE Design Block */
.ticket-booker .form-control {
  color: var(--white) !important;
  background-color: transparent;
  border-color: #ddd;
}

.meeting-and-pickup .title-name {
  font-size: 20px;
  letter-spacing: 0.5px;
}

/* End: VE Design Block */

// start banner image grid view
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Space between images */
}

.image-item {
  cursor: pointer;
  width: calc(25% - 10px);
  /* Adjust based on your grid layout */
  height: auto;
  text-align: center;
  /* Center the radio button */
}

.image-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-bottom: 5px;
  /* Space between image and radio button */
  border-radius: 5px;
}

.primary-radio {
  display: inline-block;
}

// end banner image grid view

// start commision text in user admin's listing
.input-wrapper-comm {
  position: relative;
  display: inline-block;
}

.text-input-comm {
  padding-right: 30px;
  /* Add space for the postfix */
  width: 70px;
  /* Adjust as needed */
}

.postfix-comm {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #333;
}

// end commision text in user admin's listing

.cart-counter {
  display: flex;
  max-width: 80px;
  border: 2px solid #fac532;
  border-radius: 20px;
  padding: 0.3em;
}

.cart-counter button,
.cart-counter input {
  border: 0;
  text-align: center;
}

.cart-counter input {
  color: #000;
  width: 30px;
  max-height: 30px !important;
  border-top: 1px solid #fac532;
  border-bottom: 1px solid #fac532;
  font-weight: 600;
}

.cart-counter input::-webkit-outer-spin-button,
.cart-counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-counter input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.cart-counter button {
  background-color: #fac532;
  color: #000;
}

.cart-counter button:first-child {
  border-radius: 20px 0 0 20px;
}

.cart-counter button:last-child {
  border-radius: 0 20px 20px 0;
}

.pattern-experiences {
  padding: 4em 0 6em;
}

.pattern-experiences::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 32%;
  height: 100%;
  background-image: url("../images/patterns/pattern1.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
}

.pattern-experiences::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-image: url("../images/patterns/pattern2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  background-position: bottom;
}

.hot-deals.pattern-experiences::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  background-image: url("../images/patterns/pattern3.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
}

.hot-deals.pattern-experiences::after {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  width: 30%;
  height: 100%;
  background-image: url("../images/patterns/pattern4.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}

.reef.pattern-experiences::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/patterns/pattern5.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.reef.pattern-experiences::after {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  background-image: none;
}

.new-experiences.pattern-experiences .join-blocks-img {
  height: 220px;
}

.new-experiences.pattern-experiences.top-new-experiences .join-blocks-img {
  height: 225px;
}

.profile p,
.profile ul li,
.profile span {
  color: #fff;
  text-align: left;
}

.persons-description>span {
  cursor: pointer;
  display: flex;
  justify-content: end;
  color: #000;
  font-size: 1.5em;
}

.profile span>svg {
  color: #ddb12e;
}

.profile p {
  line-height: 1.7em;
}

.profile h2 {
  text-align: left;
  color: #ddb12e;
  font-weight: 600;
  text-transform: capitalize;
}

.review-stars {
  justify-content: center;
}

.review-stars svg {
  margin: 0 4px 10px;
  height: 28px;
  width: 28px;
}

.review-stars+div>span {
  font-size: 18px;
  margin: 0 5px 10px;
  display: inline-block;
}

hr {
  border-color: #555;
  margin: 3em 0;
}

.person-group-details span {
  color: #aaa;
}

.policy-btn {
  text-decoration: none;
  position: relative;
  color: #fff;
  font-size: 16px;
  margin: 20px 12px 0;
  display: inline-block;
  font-weight: 500;
}

.policy-btn::before {
  content: "";
  position: absolute;
  width: 98%;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #c4c4c4;
}

.policy-btn:hover,
.policy-btn:focus {
  color: #ddb12e;
  text-decoration: none;
}

.card-slider {
  position: relative;
  margin: 2em 0;
}

#carousel {
  position: relative;
  height: 490px;
  overflow: hidden;
}

#carousel div {
  position: absolute;
  transition: transform 1s, left 1s, opacity 1s, z-index 0s;
  opacity: 1;
  transition: all 0.7s ease;
}

#carousel div img {
  width: 100%;
  transition: transform 0.2s ease;
  height: 100%;
}

#carousel div.hideLeft {
  left: 0%;
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

#carousel div.hideLeft img {
  width: 200px;
}

#carousel div.hideRight {
  left: 100%;
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

#carousel div.hideRight img {
  width: 200px;
}

#carousel div.prev {
  z-index: 5;
  left: 0;
  transform: translateY(64px);
  border-radius: 8px;
  overflow: hidden;
}

#carousel div.prev img {
  width: 550px;
}

#carousel div.prevLeftSecond {
  z-index: 4;
  left: 15%;
  transform: translateY(50%) translateX(-50%);
  opacity: 0.7;
  transition: all 0.2s ease;
}

#carousel div.prevLeftSecond img {
  width: 200px;
  transition: all 0.2s ease;
}

#carousel div.selected {
  z-index: 10;
  left: 50%;
  transform: translateY(0px) translateX(-50%);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 755px;
}

#carousel div.next {
  z-index: 5;
  right: 0;
  transform: translateY(64px);
  border-radius: 8px;
  overflow: hidden;
}

#carousel div.next img {
  width: 550px;
}

#carousel div.nextRightSecond {
  z-index: 4;
  left: 85%;
  transform: translateY(50%) translateX(-50%);
  opacity: 0.7;
}

#carousel div.nextRightSecond img {
  width: 200px;
}

.buttons button {
  border: 0;
  background: none;
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;
}

.buttons button:first-child {
  left: 23%;
}

.buttons button:last-child {
  right: 23%;
}

// .modal-content {
// 	color: #000;
// }

// .modal-title { color: #000; text-transform: capitalize;}
// .modal-header .close {
// 	position: absolute;
//     right: 20px;
//     top: 50%;
//     transform: translateY(-50%);
// 	margin-top: 0;
// }
// .modal-body ul { padding: 0 0 0 1.5em;}
// .modal-body p {
// 	font-size: 18px;
// 	margin: 0 0 .7em;
// }
// .modal-body ul li { font-size: 16px; margin: 0 0 .7em;}

/* .h-100 { float: left; width: 100%; padding: 110px 0 0; text-align: center;} */
.h-100 h1 {
  color: #c4a444;
}

.h-100 h3 {
  color: #fff;
}

.registration-form .breadcrum li {
  width: 90%;
  margin: 0 0 0.5em;
}

.registration-form .breadcrum li:last-child {
  width: 80%;
  margin: 0;
}

.registration-form input[type="text"] {
  background-color: #3d3d3d;
  font-size: 16px;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 30px;
  color: #fff;
  width: 100%;
  float: left;
  padding: 10px 30px;
}

.registration-form .highlights span {
  width: calc(50% - 1em);
  margin: 0 1em 1em 0;
}

.registration-form .highlights span:nth-child(even) {
  width: 50%;
  margin: 0 0 1em 0;
}

.registration-form textarea {
  background-color: #3d3d3d;
  padding: 10px 30px;
  font-size: 16px;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 20px;
  color: #fff;
  width: 100%;
  float: left;
}

.registration-form .highlights textarea {
  margin: 1em 0 0;
}

.registration-form .additional-details textarea {
  margin: 0 0 2em;
}

.registration-form .booking-btn {
  width: 185px;
  border: 0;
  text-decoration: none;
  color: #fff;
  background-color: #ddb12e;
  display: block;
  border-radius: 30px;
  padding: 0.6em 1.5em;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0.3em auto 0;
}

.registration-form .booking-btn:hover,
.registration-form .booking-btn:focus {
  background-color: #000;
}

.registration-form .card-slider {
  display: flex;
  justify-content: space-between;
}

/* .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
} */
/* .inputfile + label {
    width: 80%;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    background-color: #3d3d3d;
    border-radius: 30px;
    margin: 0 6px;
    position: relative;
    padding: 10px;
	border: 1px solid #ddd;
} */
/* .inputfile + label span {
    width: 100%;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
	color: #fff;
	line-height: 1.9em;
	font-weight: 500;
} */
/* .inputfile + label strong {
    height: 100%;
    color: #fff;
    background-color: #ddb12e;
    display: inline-block;
    padding: 11px 17px 11px 13px;
    position: absolute;
    top: 0;
    right: 0;
} */

.landing-page-bg {
  background-color: #000;
}

.landing-page {
  padding: 3em 0 2em;
}

.landing-page #cssmenu {
  transform: translateX(0);
  width: 100%;
}

.landing-page #cssmenu ul {
  display: flex;
  justify-content: center;
}

// .landing-page #cssmenu > ul > li > a { font-size: 26px; padding: 0 70px; letter-spacing: 1px; border-right: 0;}
.landing-page #cssmenu>ul>li>a {
  font-size: 26px;
  padding: 0 20px;
  letter-spacing: 1px;
  border-right: 0;
}

.landing {
  padding: 8em 0 6em;
  border-top: 0;
  margin: 0;
}

.landing::before {
  content: "";
  position: absolute;
  width: 96%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 4px;
  background-color: #ffc154;
}

.landing-image {
  float: left;
  width: 100%;
  position: relative;
}

.landing-image::before {
  content: "";
  position: absolute;
  width: 70%;
  height: 70%;
  top: 6em;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  filter: blur(200px);
  z-index: 0;
}

.landing .large {
  margin: 6em 0 0;
}

.landing-details {
  float: left;
  width: 100%;
}

.landing-details p {
  margin: 2em 0;
  font-size: 22px;
  text-align: center;
  line-height: 1.5em;
}

.landing-details .small {
  margin: 2em auto 0;
  width: 90%;
}

.quote {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 0 0 8em;
}

.quote::before {
  content: "";
  position: absolute;
  width: 96%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 4px;
  background-color: #ffc154;
}

.quote img {
  margin: 0 auto 1.7em;
}

.quote p {
  color: #fff;
  font-style: italic;
  font-size: 28px;
  line-height: 1.3em;
  margin: 0 auto 2em;
  width: 46%;
}

.quote p>span {
  display: block;
}

.quote h2 {
  color: #fdb539;
  margin-top: 0;
}

.quote .partners {
  margin: 3em auto 0;
  width: 70%;
}

.quote-tutorial {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 0 0 0;
}

.quote-tutorial p {
  color: #fff;
  font-style: italic;
  font-size: 28px;
  line-height: 1.3em;
  margin: 0 auto 0.5em;
  width: 58%;
}

.quote-tutorial p>span {
  display: block;
}

.footer {
  background-color: #000;
  padding: 4em 0;
}

.srv-validation-message {
  color: rgb(251 122 47);
  font-size: 13px;
  margin-top: 2px;
}

.active-menu .no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .maskot-footer {
    position: absolute;
    left: 22px;
    top: -17px;
    width: 150px;
    filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
    z-index: 20;
  }

  .banner-txt {
    padding-bottom: 40px !important;
  }
}

@media (min-width: 1200px) {
  .heading {
    font-size: 2.5rem;
  }

  .footer-menu li a {
    font-size: 1.3rem;
  }
}

@media (max-width: 1886px) {
  .banner-txt {
    padding: 0 0 0 3em;
    width: 118%;
  }

  /* .custom-carousel-wrapper .carousel-indicators {left: 0;transform: translateX(-130px);} */
}

@media (max-width: 1199px) {
  .banner-txt p {
    letter-spacing: 1px;
  }

  .slider-controls {
    margin: 0.5em 0 0;
  }

  .experiences::after {
    width: 55%;
    right: -33%;
  }
}

@media (max-width: 991px) {
  .join-blocks a {
    font-size: 1em;
  }

  #cssmenu {
    transform: translateX(0);
  }

  #cssmenu #menu-button:before {
    top: 17px;
    right: 17px;
  }

  #cssmenu #menu-button:after {
    top: 23px;
    right: 17px;
  }

  #cssmenu ul {
    margin: 0;
    transform: translateY(104px);
  }

  #cssmenu ul li {
    border-top: 0;
  }

  #cssmenu>ul>li>a,
  #cssmenu>ul>li:last-child>a {
    padding: 15px 20px;
    background-color: #000;
  }

  #cssmenu #menu-button:after {
    border-width: 2px;
    right: auto;
    left: 17px;
    top: 16px;
    border-color: #fff;
    transition: none;
  }

  #cssmenu #menu-button:before {
    height: 2px;
    right: auto;
    left: 17px;
    top: 10px;
    background-color: #fff;
    transition: none;
  }

  #cssmenu #menu-button.menu-opened:before {
    top: 85px;
    background: #fff;
  }

  #cssmenu #menu-button.menu-opened:after {
    top: 85px;
    background: #fff;
    height: 2px;
  }

  .landing-page #cssmenu ul {
    display: block;
  }

  .landing-page #cssmenu ul {
    transform: translateY(10px);
  }

  .landing-page #cssmenu>ul>li>a,
  .landing-page #cssmenu>ul>li:last-child>a {
    padding: 15px 20px;
  }

  .landing-page #cssmenu #menu-button.menu-opened:before {
    top: 10px;
  }

  .landing-page #cssmenu #menu-button.menu-opened:after {
    top: 10px;
  }

  .landing-image>img {
    margin: 0 auto;
  }

  .landing .large {
    margin: 6em auto 0;
  }

  .experiences .join-blocks h4 {
    font-size: 18px;
  }

  .maskot {
    top: 27%;
  }

  .banner-txt {
    width: 100%;
  }

  .banner-txt::before,
  .banner-txt::after {
    width: 390px;
  }

  .banner-txt h2 {
    font-size: 2em !important;
  }

  .banner-txt h2,
  .banner-txt p {
    white-space: nowrap;
  }

  .profile .col-lg-9,
  .profile .col-lg-3 {
    width: 100%;
  }

  .reservation-form .persons {
    width: 100%;
  }

  .reservation-form {
    height: auto;
  }

  /* .custom-carousel-wrapper .carousel-indicators {transform: translateX(-55px);} */
  .experiences::after {
    right: -53%;
  }

  #carousel div.selected img {
    width: 600px;
  }

  .buttons button:first-child {
    left: 12%;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0.3em;
    border-radius: 6px;
  }

  .buttons button:last-child {
    right: 12%;
    z-index: 21;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0.3em;
    border-radius: 6px;
  }
}

@media (max-width: 767px) {
  .slider-controls {
    margin: 0;
    display: none;
  }

  .banner-txt {
    text-align: center;
    background-color: #fac532;
    height: auto !important;
    padding: 1.5em 0;
    align-items: center;
    margin: 0 auto;
    z-index: 1;
  }

  .banner-txt h2 {
    font-size: 2em;
    letter-spacing: 1px;
    margin: 0;
  }

  .banner-txt p {
    font-size: 26px;
    margin: 0 0 0.4em;
  }

  .banner-txt h2,
  .banner-txt p {
    white-space: normal;
  }

  .banner-txt::before,
  .banner-txt::after {
    width: 100%;
  }

  .banner .item>img {
    height: 320px !important;
  }

  .book-now {
    padding: 0.6em 1.2em;
    font-size: 16px;
    letter-spacing: 2px;
  }

  .custom-carousel-wrapper .carousel-control {
    display: flex;
  }

  .wavey.desk {
    display: none;
  }

  .wavey.mob {
    margin: auto;
    display: block;
    right: 0;
    height: auto;
    left: 0;
    width: 100%;
    max-width: 100%;
  }

  .custom-carousel-wrapper .carousel-control {
    transform: translateY(-50%);
    top: 50%;
  }

  .custom-carousel-wrapper .left.carousel-control .img-responsive {
    left: 30px;
    width: 30px;
  }

  .custom-carousel-wrapper .right.carousel-control .img-responsive {
    right: 30px;
    width: 30px;
  }

  .top-experiences .util-theme-default .util-prev img,
  .top-experiences .util-theme-default .util-next img {
    width: 20px;
  }

  /* .keypoints .keypoints-container { margin: 0 0 1.5em;} */
  /* .keypoints-bg .col-xs-6:nth-child(3) .keypoints-container, .keypoints-bg .col-xs-6:last-child .keypoints-container { margin: 0;} */
  .keypoints .keypoints-container::before {
    left: 50%;
    top: 2px;
    width: 40px;
    height: 22px;
    transform: translateX(-50%);
  }

  footer {
    padding: 40px 0 40px;
  }

  footer .footer-logo img {
    max-width: 250px;
  }

  .maskot {
    width: 110px;
    top: auto;
    bottom: 0;
  }

  .social-media-links {
    transform: translateY(0);
  }

  .experiences::after {
    width: 65%;
    right: -33%;
    transform: skewX(0);
  }

  .top-experiences .util-theme-default .util-navigation {
    position: static;
    margin: 1.5em 0 0;
  }

  .pattern-experiences::before {
    width: 100%;
  }

  .highlights span {
    width: 100%;
  }

  #carousel {
    height: 236px;
  }

  #carousel div.selected {
    width: 60%;
  }

  #carousel div.prev,
  #carousel div.next {
    transform: translateY(15px);
  }

  #carousel div.selected img {
    width: 100%;
    object-fit: cover;
  }

  #carousel div.prev img,
  #carousel div.next img {
    width: 300px;
    object-fit: cover;
  }

  .banner .col-lg-4,
  .banner .col-lg-8 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .maskot-header {
    width: 110px;
  }

  .maskot-footer {
    width: 100px;
  }

  .registration-form .highlights span,
  .registration-form .highlights span:nth-child(even) {
    width: 100%;
    margin: 0 0 1em;
  }

  .highlights span>div {
    width: calc(100% - 56px);
  }

  .profile {
    padding: 7em 0 8em;
  }

  .registration-form {
    margin: 0;
    padding: 8em 0;
  }

  .registration-form .card-slider {
    flex-direction: column;
  }

  .inputfile+label {
    width: 100%;
    margin: 1.5em 0 0;
  }
}

@media (max-width: 716px) {
  .experiences .join-blocks h4 {
    min-height: 75px;
  }
}

@media (max-width: 500px) {
  .top-experiences {
    margin: 0;
  }

  .oa {
    margin: 4em 0 0;
  }

  .heading::after {
    width: 130px;
  }

  /* .custom-carousel-wrapper .carousel-indicators {bottom: 0;} */
  .join-blocks-img,
  .new-experiences .join-blocks-img,
  .experiences .join-blocks-img,
  .take-me-there .join-blocks-img {
    height: 150px;
  }

  .take-me-there .join-blocks h4 {
    font-size: 15px;
    min-width: 140px;
  }

  .experiences .join-blocks h4 {
    min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .keypoints .container {
    padding: 0 30px;
  }

  .take-me-there .container {
    padding: 0 15px;
  }

  .top-experiences .util-theme-default .util-navigation {
    top: -14px;
  }

  .top-deals .util-theme-default .util-navigation {
    top: -65px;
  }

  .footer-container {
    margin: 0;
    align-items: start;
  }

  .price {
    font-size: 0.8em;
  }

  // .te .heading, .top-deals .heading, .testimonials .heading { margin-left: 0;}
  .top-deals .util-carousel {
    margin: 1.5em -15px 0;
  }

  .new-experiences .util-carousel {
    margin: 1.5em 0 0;
  }

  /* .keypoints-bg { padding: 2em 1em;}	 */
  /* .keypoints .keypoints-container .image-container { margin: 0;} */
  /* .keypoints-bg .col-xs-6:nth-child(3) .keypoints-container { margin: 0 0 1.5em;} */
  /* .keypoints .keypoints-container { flex-direction: column; justify-content: center;} */
  /* .keypoints .keypoints-container h4 > span { display: inline; } */
  /* .keypoints .keypoints-container h4 { font-size: 18px; text-align: center;} */
  .experiences .col-xs-6 {
    width: 100%;
  }

  .result-listing .col-xs-6 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #carousel div.selected {
    width: 100%;
  }

  #carousel div.prev img,
  #carousel div.next img {
    width: 100%;
  }

  .buttons button:first-child {
    left: 2%;
  }

  .buttons button:last-child {
    right: 2%;
  }
}

@media (max-width: 420px) {
  #cssmenu ul {
    transform: translateY(87px);
  }

  .top-experiences .util-carousel .util-wrapper-holder {
    padding: 0;
  }

  // .heading, .top-deals .heading, .take-me-there .heading { font-size: 2em;}
  .heading::before {
    background-size: 58px;
    width: 58px;
    height: 20px;
  }

  .reservation-form {
    border: 0;
    padding: 0;
    background: #111;
  }

  .persons-description {
    background-color: #111;
  }

  .person-group-details {
    padding: 15px 10px 15px 40px;
    background: #3d3d3d;
  }

  .ticket-booker i.fa-calendar,
  .person-group-details i {
    left: 12px;
  }
}

@media (max-width: 370px) {
  .experiences .col-xs-6 {
    width: 100%;
  }

  .experiences .join-blocks h4 {
    min-height: auto;
  }

  .take-me-there .join-blocks h4 {
    min-width: 110px;
  }
}