#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu #menu-button {
  display: none;
}
#cssmenu{
  float: left;
}
#cssmenu > ul > li {
  float: left;
}
#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}
#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}
#cssmenu.align-center ul ul {
  text-align: left;
}
#cssmenu.align-right > ul > li {
  float: right;
}
#cssmenu > ul > li > a {
	color:#fff;
	font-weight:500;
	margin-right:0;
	padding:0 20px;
	font-size:16px;
	text-decoration:none;
	text-transform:uppercase;
	position: relative;
	z-index: 1;
	letter-spacing: .2px;
	border-right: 1px solid #1a1d1e;
}
#cssmenu > ul > li:last-child > a {
	border: 0;
}
#cssmenu > ul > li:hover > a {
  color: #ffffff;
}
#cssmenu > ul > li > a > img {
	width: 15px;
	margin: -4px 8px 0 0;
}
#cssmenu > ul > li.has-sub > a {
  padding-right: 20px;
}
#cssmenu > ul > li.has-sub > a:after {
  position: absolute;
  top: 33px;
  right: 11px;
  width: 4px;
  height: 1px;
  display: block;
  background: #fff;
  content: '';
  transform: rotate(45deg);
}
#cssmenu > ul > li.has-sub > a:before {
  position: absolute;
  top: 31px;
  right: 10px;
  display: block;
  width: 1px;
  height: 5px;
  background: #fff;
  content: '';
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  transform: rotate(45deg);
}
#cssmenu > ul > li.has-sub:hover > a:before, #cssmenu > ul > li.has-sub:hover > a:after {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
#cssmenu ul ul {
  position: absolute;
  left: -9999px;
  z-index:2;
  width:241px;
}
#cssmenu.align-right ul ul {
  text-align: right;
}
#cssmenu ul ul li {
  height: 0;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  border-top:1px solid rgba(255,255,255,.15);
}
#cssmenu li:hover > ul {
  left: 10px;
}
#cssmenu.align-right li:hover > ul {
  left: auto;
  right: 0;
}
#cssmenu li:hover > ul > li {
  height: 35px;
}
#cssmenu ul ul ul {
  margin-left: 100%;
  top: -1px;
}
#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}
#cssmenu ul ul li a {   
  padding: 18px 16px 20px 16px;
  min-width: 250px;
  font-size: 13px;
  text-decoration: none;
  color: #000;
  font-weight: 400;
  background: #fff;
  display:block;
  text-transform: capitalize;
}
#cssmenu ul ul li:last-child > a,
#cssmenu ul ul li.last-item > a {
  border-bottom: 0;
}
#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #ddaa44;
}
#cssmenu ul ul li.has-sub > a:after {
  position: absolute;
  top: 20px;
  right: 11px;
  width: 6px;
  height: 2px;
  display: block;
  background: #0D0C1D;
  content: '';
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  transform: rotate(45deg);
}
#cssmenu.align-right ul ul li.has-sub > a:after {
  right: auto;
  left: 11px;
}
#cssmenu ul ul li.has-sub > a:before {
  position: absolute;
  top: 22px;
  right: 13px;
  display: block;
  width: 2px;
  height: 6px;
  background: #0D0C1D;
  content: '';
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  transform: rotate(45deg);
}
#cssmenu.align-right ul ul li.has-sub > a:before {
  right: auto;
  left: 14px;
}
/*#cssmenu ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}*/
#cssmenu ul ul > li.has-sub:hover > a:after {
  top: 24px;
  right:14px;
}

#cssmenu ul > .active > a, #cssmenu ul > .active > a:focus, #cssmenu ul > .active > a:hover{background-color:#800000;color:#fff;font-weight:600;}
#cssmenu ul > li > a:focus, #cssmenu ul > li > a:hover{color:#ddb12e;transition:all 0.1s ease;}

@media all and (max-width: 991px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 991px), only screen and (min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min-resolution: 192dpi) and (max-width: 991px), only screen and (min-resolution: 2dppx) and (max-width: 991px) {
  #cssmenu {
    width: 100%;
  }
  #cssmenu li:hover > ul {
	left: 0;
  }
  #cssmenu ul {
    width: 100%;
    display: none;
  }
  #cssmenu.align-center > ul {
    text-align: left;
  }
  #cssmenu ul li {
    width: 100%;
    border-top: 1px solid rgba(120, 120, 120, 0.2);
  }
  #cssmenu ul ul li,
  #cssmenu li:hover > ul > li {
    height: auto;
  }
  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
    border-bottom: 0;
  }
  #cssmenu > ul > li {
    float: none;
  }
  #cssmenu ul ul li a {
    padding-left: 33px;
  }
  #cssmenu ul ul ul li a {
    padding-left: 35px;
  }
  #cssmenu ul ul li a {
    color: #dddddd;
    background: none;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li.active > a {
    color: #ffffff;
  }
  #cssmenu ul ul,
  #cssmenu ul ul ul,
  #cssmenu.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }
  #cssmenu > ul > li.has-sub > a:after,
  #cssmenu > ul > li.has-sub > a:before,
  #cssmenu ul ul > li.has-sub > a:after,
  #cssmenu ul ul > li.has-sub > a:before {
    display: none;
  }
  #cssmenu #menu-button {
    display: block;
    padding: 17px 0;
    color: #dddddd;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
  }
  #cssmenu #menu-button:after {
    position: absolute;
    top: 22px;
    right: 17px;
    display: block;
    height: 8px;
    width: 25px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    content: '';
	transition: all .15s linear;
  }
  #cssmenu #menu-button:before {
    position: absolute;
    top: 16px;
    right: 17px;
    display: block;
    height: 1px;
    width: 25px;
    background: #fff;
    content: '';
	transition: all .15s linear;
  }
  #cssmenu #menu-button.menu-opened:after {
    top: 23px;
    border: 0;
    height: 1px;
    width: 20px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #cssmenu #menu-button.menu-opened:before {
    top: 23px;
    background: #ffffff;
    width: 20px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  #cssmenu .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(120, 120, 120, 0.2);
    height: 44px;
    width: 36px;
    cursor: pointer;
  }
  #cssmenu .submenu-button.submenu-opened {
    background: #262626;
  }
  #cssmenu ul ul .submenu-button {
    height: 34px;
    width: 34px;
  }
  #cssmenu .submenu-button:after {
    position: absolute;
    top: 19px;
    right: 13px;
    width: 2px;
    height: 8px;
    display: block;
    background: #fff;
    content: '';
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
  }
  #cssmenu ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
  }
  #cssmenu .submenu-button.submenu-opened:after {
    background: #ffffff;
  }
  #cssmenu .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 17px;
    display: block;
    width: 2px;
    height: 8px;
    background: #dddddd;
    content: '';
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
  }
  #cssmenu ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
  }
  #cssmenu .submenu-button.submenu-opened:before {
    transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
  }
  #cssmenu .submenu-button.submenu-opened:after {
    transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
  }
}