.bookDemo_wrapper {
  margin-bottom: 80px;

}

.gradient-line {
  height: 2px;
  /* Adjust the height as needed */
  width: 100%;
  background: linear-gradient(90deg,
      #141414 0%,
      #e36d4b 33.33%,
      #e36d4b 66.67%,
      #141414 100%);
  margin-top: 20px;
  margin-bottom: 80 !important;
}

.centered-image {
  position: absolute;
  /* Stay centered */
  top: 0%;
  /* Center vertically on the gradient line */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for true center alignment */
  height: 55px;
  /* Adjust height as necessary */
  object-fit: cover;
  /* Ensures the image covers the area without distortion */
  z-index: 2;
  /* Make sure the image is above the contact form */

  @media (max-width: 768px) {
    height: 45px;
    /* Adjust height for smaller screens */
  }

  @media (max-width: 480px) {
    height: 35px;
    /* Further adjust for very small screens */
  }
}

.overlay-text {
  position: absolute;
  /* Position relative to the nearest positioned ancestor */
  top: 0%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for true center alignment */
  color: #ffffff;
  font-size: 20px;
  /* Adjust font size as needed */
  font-weight: 600;
  text-align: center;
  z-index: 3;
  /* Ensure the text appears above the image */

  @media (max-width: 768px) {
    font-size: 18px;
    /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 16px;
    /* Further adjust for very small screens */
  }
}

.contact-form-wrapper {
  margin-top: -80px !important;
  /* Space between the image and the form */
  padding: 20px;
  background-color: #141414;
  /* Dark background to match the theme */
  border: 1px #111111;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  /* Optional shadow for depth */
  position: relative;
  /* Ensure form stays below the image */
  z-index: 1;
  /* Make sure the form is below the image */

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
}

.contact-form-title {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

#contactformrest {
  display: flex;
  flex-direction: column;
}

#restaurantform {
  margin-bottom: 15px;
  // background-color: #191919;
  width: 100%;
  max-width: 452px;
  text-align: center;
}

#restaurantform .srv-validation-message {
  text-align: left !important;
}

#locationInput {
  margin-bottom: 15px;
  // background-color: #191919;
  width: 100%;
  max-width: 452px;
  text-align: center;
}

#locationInput .srv-validation-message {
  text-align: left !important;
}

#menuform .srv-validation-message {
  text-align: left !important;
}

#radiohead .srv-validation-message {
  text-align: left !important;
  margin-left: 55px;
}
// .input,
// textarea {
//   width: 100%;
//   height: 72px;
//   padding: 10px;
//   border-radius: 4px;
//   border: 1px #282828;
//   background-color: #191919;
//   color: #BABABA;
//   font-size: 17px;
// }


.bookDemo_wrapper input,
.bookDemo_wrapper textarea {
  width: 100%;
  height: 72px;
  padding: 10px;
  padding-left: 50px !important;
  border-radius: 4px;
  border: 1px solid #282828;
  background-color: #191919;
  color: #BABABA;
  font-size: 17px;
}

@media (max-width: 768px) {
  .bookDemo_wrapper input::placeholder {
    font-size: 14px !important;
  }

  .bookDemo_wrapper input,
  .bookDemo_wrapper textarea {
    padding-left: 30px !important;
  }
}

// #firstNameInput,
// #lastNameInput,
// #phoneNumberInput,
// #emailInput,
// #datePicker,
// #locationInput2,
// #timeInput,
// textarea {
//   width: 100%;
//   height: 72px;
//   // padding: 10px;
//   border-radius: 4px !important;
//   border: 1px #282828;
//   background-color: #191919;
//   color: #bababa;
//   font-size: 17px;
// }

textarea {
  resize: vertical;
  min-height: 100px;
  border-radius: 4px !important;
  border: 1px #282828;
}

.submit-btn {
  background-color: #EEAD20;
  color: #ffffff;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 452px;
  max-height: 72px;

  &:hover {
    background-color: #c5573f;
  }
}

@media (max-width: 768px) {
  .contact-form-title {
    font-size: 20px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .contact-form-title {
    font-size: 18px;
  }

  .submit-btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}



.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;

}

.input-icon {
  position: absolute;
  left: 10px;
  /* Space from the left */

}

.input-with-icon input {
  padding-left: 40px;
  /* Space for the icon */
  width: 100%;
  /* Full width */
  border: 1px solid #282828;
  /* Add border style as needed */
  background-color: #191919;
  border-radius: 5px;
  /* Add border radius as needed */
  font-family: inherit;
  /* Inherit font family from parent */
  color: #BABABA;
  /* Input text color */
  outline: none;
  /* Remove outline */
}

.input-with-icon input:focus {
  border-color: #e36d4b;
  /* Change border color on focus */
}



@media (max-width: 768px) {
  #locationInput {
    max-width: 100% !important;
    /* Make the input field take full width on smaller screens */
  }
}

@media (max-width: 480px) {
  #locationInput {
    max-width: 100% !important;
    /* Further ensure it stays at 100% for very small screens */
  }
}


#restaurantform {
  max-width: 100%;
}