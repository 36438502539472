#headerButoons {
  font-family: Poppins;
}

@media (min-width: 1200px) {
  /* Adjust the min-width value as needed to target your definition of large screens */
  #headerButtons {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  #headerButtons {
    margin-top: 30px;
    margin-bottom: 40px;
    gap: 0;
  }
  .btn logout {
    margin-top: -50px !important;
    margin-bottom: 20px;
  }
 
}
