.earlyresigter_wrapper {
  margin-top: 80px !important;
  .row {
    height: 100%;
  }

  .col-12.col-lg-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 0;
    padding: 20px;
  }
}

#earlyregisterheading {
  font-size: 25px;
  margin-bottom: 1rem !important;
  font-family: Arial Rounded MT Std;
  color: #eead20;
}
