.feature-wrapper {
  background-color: #111111;
  border: 1px solid #1c1c1c;
}

.about_wrapper {
  margin-top: 80px !important;
}

#aboutWrapper {
  height: 513px;
}

#aboutheading {
  font-size: 25px;
  text-align: center;
  color: #eead20;
  margin-bottom: 1rem;
  font-family: Arial Rounded MT Std;
}

.text-content {
  color: #dadada !important;
  font-family: Poppins !important;
}
