/* Bootstrap Icons */
@import "~bootstrap-icons/font/bootstrap-icons";

:root {
  --black: #000;
  --white: #fff;
  --green: #2ae404;
  --theme-color1: #ddb12e;
  --theme-color2: #c4a444;
  --theme-color3: #ffbe53;
  --theme-dark1: #0e1013;
  --theme-gray1: #606161;
  --theme-gray2: #3d3d3d;
  --theme-lightgray: #e8ecf0;
  --theme-lightgray2: #bababa;
}

html,
body {
  color: var(--white);
  font-size: 16px;
  overflow-x: hidden;
}

.main-content {
  min-height: 47vh;
}

.react-loading-skeleton {
  --base-color: #e1e1e1 !important;
  --highlight-color: #c1c1c1 !important;
}

/* End: Custom Global CSS */
.bg-white {
  background-color: var(--white);
}

.bg-black {
  background-color: var(--black);
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-red {
  color: #e00303 !important;
}

.text-green {
  color: var(--green) !important;
}

.text-blue {
  color: blue !important;
}

.text-custom-primary {
  color: var(--theme-color1) !important;
}

.text-custom-primary2 {
  color: var(--theme-color3) !important;
}

.text-custom-secondary {
  color: var(--theme-lightgray2) !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.form-group {
  margin-bottom: 1rem;
}

.main-heading {
  font-size: 25px;
  font-weight: 700;
  color: var(--theme-color1);
  text-transform: capitalize;
}

.main-heading1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--theme-color1);
}

.main-heading2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-color1);
  line-height: normal;
}

.divider {
  border-width: 3px;
  border-color: #4d4d4d;
  margin-top: 25px;
  margin-bottom: 25px;
}

.custom-row {
  margin-right: -8px;
  margin-left: -8px;
}

.custom-row>*,
.custom-row>div {
  padding-right: 8px;
  padding-left: 8px;
}

label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.table-custom>thead>tr>th {
  color: var(--theme-color1);
  white-space: nowrap;
}

.table-borderless> :not(caption)>*>* {
  border-width: 0 !important;
}

.btn-custom {
  color: var(--black);
  background-color: var(--theme-color1);
  font-size: 24px;
  font-weight: 600;
  min-width: 180px;
  min-height: 55px;
  border: 0;
  border-radius: 10px;
  text-align: center;
  outline: 1.5px solid #fff !important;
  outline-offset: -6px !important;
  letter-spacing: 0.5px;
  padding: 8px 30px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 11;
}

.btn-custom-red {
  color: var(--black);
  background-color: #ff0000;
  font-size: 24px;
  font-weight: 600;
  min-width: 180px;
  min-height: 55px;
  border: 0;
  border-radius: 10px;
  text-align: center;
  outline: 1.5px solid #fff !important;
  outline-offset: -6px !important;
  letter-spacing: 0.5px;
  padding: 8px 30px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 11;
}

.btn-custom-md {
  font-size: 20px !important;  
  min-width: 130px !important;
  min-height: 40px !important;
}

.btn-custom-tutorial {
  color: var(--black);
  background-color: #b3b0a969;
  font-size: 24px;
  font-weight: 600;
  min-width: 180px;
  min-height: 55px;
  border: 0;
  border-radius: 10px;
  text-align: center;
  outline: 1.5px solid #fff !important;
  outline-offset: -6px !important;
  letter-spacing: 0.5px;
  padding: 8px 30px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  // z-index: 11;
}

.btn-custom-sm {
  font-size: 14px;
  min-width: 120px;
  min-height: 31px;
  border-radius: 7px;
  outline-offset: -4px !important;
  padding: 5px 20px;
}

.btn-custom:before {
  content: "";
  height: 115%;
  width: 115%;
  border-radius: 50%;
  background-color: #ce971e;
  position: absolute;
  left: -7.5%;
  bottom: 22px;
  z-index: -1;
  border-radius: 50%;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active {
  color: var(--white) !important;
  background-color: var(--theme-color2) !important;
}

.btn-custom-red:hover,
.btn-custom-red:focus,
.btn-custom-red:active {
  color: var(--white) !important;
  background-color: #ff0000 !important;
}

.btn-custom2 {
  color: var(--white);
  font-size: 19px;
  font-weight: 700;
  min-width: 120px;
  min-height: 48px;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 8px 30px;
  border: 0;
  border-radius: 10px;
  background-color: var(--theme-color1);
  outline: none !important;
  outline-offset: 0 !important;
  transition: all 0.3s;
}

.btn-custom2:hover,
.btn-custom2:focus,
.btn-custom2:active {
  color: var(--white);
  background-color: var(--theme-color3) !important;
}

/* input:not([type="radio"], [type="checkbox"]), select { */
.form-control {
  color: var(--black);
  height: 40px;
  font-size: 16px;
}

textarea.form-control {
  height: auto;
}

/* .custom-field-wrapper input:not([type="radio"], [type="checkbox"]),
.custom-field-wrapper select,
.custom-field-wrapper textarea { */
.custom-field-wrapper .form-control {
  background-color: var(--theme-lightgray2);
  border: 2px solid var(--white);
  /* padding: 7px 14px; */
  outline: none;
}

.custom-field-wrapper ::-webkit-input-placeholder {
  font-size: 14px;
  color: var(--theme-dark1);
}

.custom-field-wrapper :-moz-placeholder {
  /* Upto Firefox 18, Deprecated in Firefox 19  */
  font-size: 14px;
  color: var(--theme-gray1);
}

.custom-field-wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
  color: var(--theme-gray1);
}

.custom-field-wrapper :-ms-input-placeholder {
  font-size: 14px;
  color: var(--theme-gray1);
}

/* Custom Checkbox Button CSS */
input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-left: 3px !important;
  /* margin-right: 8px !important; */
  cursor: pointer;
  font-size: 17px;
  /* visibility: hidden; */
  -webkit-appearance: none;
  appearance: none;
}

input[type="checkbox"]::after {
  content: " ";
  background-color: transparent;
  /* display: inline-block; */
  display: table-caption;
  color: var(--green);
  width: 25px;
  height: 25px;
  visibility: visible;
  border: 1px solid var(--white);
  padding-left: 4.5px;
  border-radius: 5px;
}

input[type="checkbox"]:checked::after {
  content: "\2714";
  background-color: transparent;
}

input[type="checkbox"]:focus {
  outline: none;
}

/* Custom Radio Button CSS */
input[type="radio"] {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  accent-color: var(--theme-color1);
}

.custom-radio {
  appearance: none;
  -webkit-appearance: none;
  border: 5px solid var(--black);
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 0 0 0 1px var(--theme-color1);
}

/* keyboard navigation focus appearance */
.custom-radio:focus-visible,
.custom-radio:focus {
  outline-offset: 0 !important;
  outline: none !important;
}

.custom-radio:hover {
  background-color: var(--theme-color1);
}

.custom-radio:checked {
  box-shadow: 0 0 0 1px var(--theme-color1);
  background-color: var(--theme-color1);
  border-width: 5px;
}

/* Custom input type file field design */
.custom-input-file {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-input-file .inputfile {
  display: none;
}

.custom-input-file .inputfile+label {
  height: 40px;
  width: 100%;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--white);
  position: relative;
  padding: 5px 12px;
  border: 2px solid #ddd;
  // border-radius: 30px;
  border-radius: var(--bs-border-radius);
}

.custom-field-wrapper .custom-input-file .inputfile+label {
  background-color: var(--theme-lightgray2);
}

.custom-input-file .inputfile+label span {
  color: var(--black);
  width: calc(100% - 50px);
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: auto;
}

.custom-input-file .icon {
  height: 100%;
  width: 50px;
  background-color: #ddb12e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.react-multi-carousel-list {
  margin-left: -10px;
  margin-right: -10px;
}

.react-multi-carousel-list .react-multi-carousel-track>li {
  padding-left: 10px;
  padding-right: 10px;
}

/* bootstrap model customization */
.custom-modal .bg-box {
  background-image: linear-gradient(0deg,
      rgba(61, 61, 61, 0.2) 2%,
      rgba(0, 0, 0, 0.2) 100%);
  border: 1px solid #323232;
  border-radius: 20px;
}

.custom-modal .modal-content {
  color: #fff;
  background-color: var(--black);
  border: 7px solid #181a20;
  border-radius: 20px;
  overflow: hidden;
}

.custom-modal .modal-header {
  background-color: var(--theme-color1);
  border-bottom: #000;
  padding: 12px 16px;
  position: relative;
}

.custom-modal .modal-header button.close {
  opacity: 1;
  text-shadow: none;
}

.custom-modal .modal-header .modal-title {
  line-height: normal;
  color: #000;
  text-transform: capitalize;
}

.custom-modal .modal-body {
  padding: 20px 16px 30px;
}

/* bootstrap carousel customization */
.custom-carousel-wrapper {
  background-color: var(--theme-lightgray2);
}

.custom-carousel-wrapper .carousel-indicators {
  bottom: 30px;
}

.custom-carousel-wrapper .carousel-indicators [data-bs-target] {
  height: 14px;
  width: 14px;
  bottom: 40px;
  background-color: #545454;
  opacity: 1;
  margin: 0 5px;
  border-radius: 4px;
  border: 0;
}

.custom-carousel-wrapper .carousel-indicators [data-bs-target].active {
  background-color: var(--white);
}

@media(min-width:768px) {
  .custom-carousel-wrapper .carousel-indicators {
    justify-content: start;
  }
}

@media(min-width:768px) and (max-width:991px) {
  .custom-carousel-wrapper .carousel-indicators {
    transform: translateX(-44.8vw);
  }
}

@media(min-width:992px) and (max-width:1199px) {
  .custom-carousel-wrapper .carousel-indicators {
    transform: translateX(-38.8vw);
  }
}

@media(min-width:1200px) and (max-width:1886px) {
  .custom-carousel-wrapper .carousel-indicators {
    transform: translateX(-39.8vw);
  }
}

@media(min-width:1887px) {
  .custom-carousel-wrapper .carousel-indicators {
    transform: translateX(-26.8vw);
  }
}

/* End: Custom Global CSS */

/* Start: Header CSS */
.custom-header {
  /* position: sticky !important; */
  top: 0;
  background-color: var(--black);
  border-bottom: 1px solid #313131;
  padding: 1rem 0;
  float: none;

  /* After update */
  position: fixed !important;
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1030;
}

@media (min-width: 992px) {
  .custom-header-new {
    height: 113px;
  }
}

@media (max-width: 767px) {
  .custom-header .container {
    width: 100%;
  }
}

.main-content {
  margin-top: 75px;
}

/* Start: Special CSS for Long(Advertising) Device screen header 1080x1920 */
.screen-header {
  height: 68px;
}

.screen-header+.main-content {
  margin-top: 68px;
}

.screen-header .logo img {
  max-width: 150px;
}

/* End: Special CSS for Long(Advertising) Device screen header 1080x1920 */

.common-menu-wrapper ul {
  list-style: none;
}

.common-menu-wrapper ul li a {
  color: var(--white);
  text-transform: capitalize;
  text-decoration: none;
}

.common-menu-wrapper ul li a:hover,
.common-menu-wrapper ul li a:focus,
.common-menu-wrapper ul li a:active {
  color: var(--theme-color1);
  text-decoration: none;
}

@media (max-width: 991px) {
  .sidebar-drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    background: var(--black);
    border-right: 1px solid var(--theme-color1);
    transform: translateX(-100%);
    transition: all 0.3s;
    z-index: 1045;
  }

  .sidebar-drawer .logo {
    height: 71px;
    border-bottom: 1px solid var(--theme-color1);
    padding: 10px 1rem;
  }

  .sidebar-drawer .logo img {
    max-width: 150px;
  }

  .sidebar-drawer ul {
    height: calc(100% - 71px);
    padding: 1rem !important;
    overflow: auto;
  }

  body.sidebar-enabled {
    overflow: hidden;
  }

  .sidebar-enabled {
    .sidebar-drawer {
      transform: translateX(0);
    }

    .custom-header {
      &:before {
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1040;
      }
    }
  }
}

/* End: Header CSS */

/* Start: Search Result Page CSS */
.search-result-page .keypoints .container {
  max-width: 1170px;
}

.search-result-page .keypoints .keypoints-bg {
  padding-left: 0;
  padding-right: 0;
  margin: 30px 0 0;
}

.search-result-page .banner .banner-txt h2 {
  margin-bottom: 35px;
}

.search-result-page .result-listing {
  background-color: #000;
  margin: 0;
  padding: 0 0 72px;
}

.search-result-page .result-listing .heading {
  margin-top: 10px;
}

.search-result-page .result-listing .item {
  margin-top: 30px;
}

.search-result-page .testimonials {
  margin-top: 45px;
  margin-bottom: 0;
}

/* Meeting & Dropoff Popup */
.meetingPopup .product-details .form-group:not(:last-child) {
  border-bottom: 1px solid #323232;
  padding-bottom: 25px;
}

.meetingPopup .product-details .product-price {
  font-size: 30px;
  font-weight: 700;
}

/* End: Search Result Page CSS */

/* Start: Restaurants Menu Page CSS */
.menu-bg .icon-img {
  background-color: #fff;
  border: 7px solid #fec157;
  border-radius: 25px;
  border-bottom-left-radius: 0;
  overflow: hidden;
  margin-right: -10px;
  box-shadow: 0px 0px 2px 2px #01010145;
  position: relative;
  width: 65px;
  min-width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-bg .icon-img img {
  width: 40px;
}

.menu-bg .menu-label {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  background-color: #fec157;
  padding: 10px 50px 10px 50px;
  margin-left: -20px;
  text-align: left;
  border-right: 10px solid #ef9e2a;
  border-radius: 50px;
  box-shadow: 10px 0px 0px 0px #f18a20;
}

.restaurants-menu-page .info-card {
  /* background-color: #333232; */
  border: 2px solid #fec157;
  border-radius: 7px;
}

.restaurants-menu-page .info-card .description {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.restaurants-menu-page .info-card .img-sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.restaurants-menu-page .info-card .img-sec button {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  padding: 6px 15px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 11;
  border: 0;
}

.restaurants-menu-page .addMenuCart .img-sec {
  background-color: #333232;
  border: 3px solid var(--white);
  border-radius: 7px;
}

.restaurants-menu-page .addMenuCart .cart-counter {
  max-width: 120px;
  width: 100%;
}

.restaurants-menu-page .addMenuCart .cart-counter button {
  width: 30px;
  font-weight: 600;
  font-size: 18px;
}

.restaurants-menu-page .addMenuCart .cart-counter input {
  width: 50px;
}

.restaurants-menu-page .info-card .img-sec button:focus,
.restaurants-menu-page .addMenuCart .cart-counter input:focus {
  outline: none;
}

/* Specially for custom-model-2 class model */
.custom-model-2 .img-sec img {
  max-height: 310px;
  object-fit: cover;
}

.custom-model-2 .custom-close {
  color: var(--black);
  background-color: var(--theme-color1);
  padding: 5px;
  width: 60px;
  height: 45px;
  border: 0;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
}

.custom-model-2 .modal-body {
  background-image: linear-gradient(0deg, #202020 0%, #000000 100%);
}

/* End: Restaurants Menu Page CSS */

/* Start: Select Your Screen Page CSS */
.select-your-screen .theade-bg thead tr td {
  color: var(--black);
  font-weight: 700;
  line-height: normal;
  background-color: var(--theme-color1);
}

.select-your-screen .table tr td {
  padding: 5px 10px;
}

/* End: Select Your Screen Page CSS */

/* Start: Dashboard Page CSS */
.dashboard-page .theade-bg thead tr th {
  color: var(--black);
  font-weight: 700;
  line-height: normal;
  background-color: var(--theme-color1);
}

.dashboard-page .theade-bg tr th,
.dashboard-page .theade-bg tr td {
  border-color: var(--theme-color1);
}

.dashboard-page thead tr th {
  color: var(--black);
  background-color: var(--theme-color1);
}

.dashboard-page table tr>* {
  padding: 8px 10px;
  white-space: nowrap;
  border-width: 1px;
}

.dashboard-page .chart-cover {
  border: 2px solid var(--theme-color1);
  border-radius: 10px;
}

.dashboard-page .chart-wrapper button {
  min-height: 50px;
  font-size: 20px;
}

/* End: Dashboard Page CSS */

/* Start: Booking Page CSS */
.bg-box {
  background-image: linear-gradient(0deg,
      rgba(61, 61, 61, 0.2) 2%,
      rgba(0, 0, 0, 0.2) 100%);
  border: 1px solid #323232;
  border-radius: 25px;
}

// .bg-box.overview-box {
//   height: 447px;
//   overflow: auto;
// }

// .bg-box.highlights-box {
//   height: 313px;
//   overflow: auto;
// }

// .bg-box.exclusion-box {
//   height: 243px;
//   overflow: auto;
// }

.heading.main-heading2 {
  margin-top: 1rem;
  display: inline-block;
  text-transform: uppercase;
}

.heading.main-heading2:before {
  top: -15px;
  background-size: 30px;
  width: 30px;
  height: 10px;
}

.booking-form {
  color: var(--black);
  background-color: var(--white);
  border: 7px solid var(--theme-color3);
  position: relative;
  z-index: 11;
}

.booking-form .label-heading {
  color: var(--white);
  display: inline-block;
  padding: 1px 30px 12px;
  background-color: var(--theme-color3);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-transform: uppercase;
}

.booking-form .persons-info {
  color: var(--white);
  background-color: var(--theme-gray2);
  min-height: 55px;
  padding: 10px 1rem;
  border-radius: 5px;
  line-height: normal;
}

.booking-form .persons-info+.persons-info {
  margin-top: 10px;
}

.counter-box {
  color: var(--white);
  background-color: transparent;
  width: 115px;
}

.counter-box button {
  color: var(--theme-gray2);
  background-color: var(--theme-color3);
  padding: 5px;
  border: 0;
  border-radius: 3px;
  width: 35px;
  line-height: normal;
}

.counter-box button i {
  font-size: 18px;
}

.counter-box input {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  background-color: transparent;
  padding: 5px;
  width: 45px;
  height: 30px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  text-align: center;
}

.counter-box input:focus {
  outline: none;
}

.booking-form .price-box {
  background-color: var(--theme-color3);
  padding: 2px 3px;
  overflow: hidden;
}

.booking-form .total-amount {
  color: var(--theme-gray2);
  font-size: 22px;
  font-weight: 600;
  background-color: var(--white);
  padding: 8px 10px;
  text-align: center;
}

.booking-form .amount-value {
  color: var(--white);
  font-size: 30px;
  font-weight: 700;
  background-color: var(--theme-color3);
  padding: 8px 10px;
  text-align: center;
  line-height: normal;
}

.booking-form .booking-calendar input {
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  background-color: var(--theme-gray2);
  min-height: 55px;
  padding: 10px 1rem;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

.booking-form .booking-calendar input::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

.booking-form .booking-calendar input:before {
  content: '\F1F3';
  font-size: 20px;
  font-family: 'bootstrap-icons';
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: var(--theme-color3);
}

.booking-form .booking-calendar input:focus {
  outline: none;
}

.booking-form .booking-calendar i {
  color: var(--theme-color3);
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.date-pointer::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.booking-form .btn-wrapper button {
  font-size: 27px;
  font-weight: 600;
  line-height: normal;
  color: var(--white);
  background-color: var(--theme-color3);
  border: 0;
  padding: 10px 1rem;
  min-height: 55px;
  width: 100%;
  transition: all 0.3s;
}

.booking-form .btn-wrapper button:hover {
  background-color: var(--theme-color1);
}

.booking-form .check-wrapper label {
  color: var(--white);
  font-size: .875rem;
  margin: 0;
  padding: 7px 8px;
}

.modal-cover button {
  color: var(--theme-gray2);
}

.booking-form .review-box i {
  color: var(--theme-color3);
  font-size: 25px;
}

.booking-form .review-box .rating {
  color: var(--theme-gray2);
  font-size: 20px;
  font-weight: 500;
}

/* End: Booking Page CSS */

/* Start: Top Experience Section CSS */
.event-sec .input-cover input[type="date"] {
  color: var(--white);
  border: 0;
  background-color: var(--black);
  padding: 5px 1rem;
  min-height: 48px;
}

.event-sec .input-cover input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

.top-experience-page .list-wrapper2 .img-wrapper img {
  max-height: 140px;
  object-fit: cover;
}

.top-experience-page .date-and-time-cover .date-sec,
.top-experience-page .date-and-time-cover .time-sec {
  color: var(--white);
  background-color: var(--theme-color1);
  padding: 8px 6px;
  flex: 1 0 0;
}

.top-experience-page .date-and-time-cover .time-sec {
  background-color: var(--black);
}

/* End: Top Experience Section CSS */

/* Start: Restaurant Section CSS */
.restaurant-main-info .icon-sec {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 13px;
  background-color: var(--theme-color1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.restaurant-main-info .icon-sec i {
  font-size: 24px;
}

.restaurant-main-info .info-sec .label-name {
  color: #777;
}

.restaurant-main-info .info-sec .info-sec,
.restaurant-main-info .info-sec .label-value {
  font-size: 17px;
}

/* End: Restaurant Section CSS */
.restaurant-page .tab-button button {
  background-color: transparent;
  text-transform: uppercase;
}

.restaurant-page .tab-button button {
  background-color: transparent;
}

.restaurant-page .tab-button button:hover {
  background-color: var(--theme-gray2) !important;
}

.restaurant-page .tab-button button:focus,
.restaurant-page .tab-button button:active {
  background-color: transparent !important;
}

.restaurant-page .tab-button button.active {
  background-color: var(--theme-color1) !important;
}

.restaurant-page .restaurant-menu-listing .info-wrapper button {
  font-size: 14px;
  min-height: auto;
  min-width: auto;
  padding: 5px 1rem;
  background-color: var(--theme-color3);
  text-transform: uppercase;
}

/* Start: Nightlife Page CSS */
.nightlife-card-info .highlight-banner {
  height: 428px;
  overflow: auto;
}

.nightlife-card-info .highlight-banner img {
  height: 100%;
  object-fit: cover;
  display: block;
  border: none;
}

.nightlife-card-info .highlight-banner img:not([src]) {
  opacity: 0;
}


.nightlife-card-info .highlight-banner .reveal-info .left-sec {
  width: calc(100% - 60px);
}

.nightlife-card-info .highlight-banner .reveal-info .right-sec .img-wrapper {
  height: 55px;
  width: 55px;
  background-color: var(--theme-color1);
}

.nightlife-card-info .highlight-banner .reveal-info .right-sec .img-wrapper img {
  height: 55px;
  width: 55px;
  background-color: var(--theme-color1);
  margin-left: -4px;
}

.nightlife-card-info .highlight-banner .moon-wrapper {
  width: 207px;
  height: 100%;
  background-repeat: no-repeat;
}

.nightlife-card-info .highlight-banner .heading-title {
  color: var(--white);
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
}

.nightlife-card-info .highlight-banner button {
  color: var(--black);
  background: var(--theme-color3);
  position: relative;
  z-index: 11;
}

.nightlife-card-info .highlight-banner button:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: var(--theme-color3);
  transform-origin: bottom left;
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}

/* End: Nightlife Page CSS */

/* Start: Oval Button List Page */
.btn-oval1 {
  background-image: url("../images/patterns/oval-img1.png") !important;
}

.btn-oval2 {
  background-image: url("../images/patterns/oval-img2.png") !important;
}

.btn-oval3 {
  background-image: url("../images/patterns/oval-img3.png") !important;
}

.btn-oval4 {
  background-image: url("../images/patterns/oval-img4.png") !important;
}

.btn-oval5 {
  background-image: url("../images/patterns/oval-img5.png") !important;
}

.btn-oval6 {
  background-image: url("../images/patterns/oval-img6.png") !important;
}

.btn-oval7 {
  background-image: url("../images/patterns/oval-img7.png") !important;
}

.btn-oval8 {
  background-image: url("../images/patterns/oval-img8.png") !important;
}

.btn-oval9 {
  background-image: url("../images/patterns/oval-img9.png") !important;
}

.btn-oval {
  max-width: 300px !important;
  width: 100% !important;
  height: 145px !important;
  font-size: 25px !important;
  font-weight: 700;
  color: var(--white) !important;
  background-color: #555;
  background-size: 102% 103%;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: -3px -2px;
  outline: none !important;
  outline-offset: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  overflow: hidden;
  line-height: normal;
}

.button-list-page {
  min-height: calc(100vh - 68px);
  background-color: var(--black);
}

.action-btn-wrapper button {
  max-width: 744px !important;
  width: 100% !important;
  height: 180px !important;
  font-size: clamp(30px, 5vw, 68px) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success) !important;
  background: #0f7812 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error) !important;
  background: #ab2113 !important;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  /* Adjust the gap as needed */
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #ddb12e !important;
}

// ckeditor on activity create page
.custom-quill .quill {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
}

.custom-quill .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  min-height: 200px;
}

.custom-quill .ql-editor {
  // border-radius: 8px;
  padding: 10px;
  font-size: 16px;
}

.custom-quill .ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

// end ckeditor on activity create page


// stripe message
.stripe-info {
  background-color: #f8f9fa;
  border: 1px solid #e3e6ec;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.stripe-info p {
  margin: 0;
  color: #3a3a3a;
  font-size: 16px;
  line-height: 1.5;
  flex: 1;
}

.stripe-info p strong {
  font-weight: bold;
}

.stripe-info img {
  height: 40px;
  margin-left: 20px;
}

@media (min-width: 768px) {
  .action-btn-wrapper button {
    height: 384px !important;
  }
  .custom-left-sec-height{
    height: 980px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .oval-btn-list-wrapper .row>* {
    width: 50%;
    float: left;
  }
}

/* End: Oval Button List Page */


.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Responsive Css */
@media (max-width: 575px) {

  /* Restaurant Page CSS */
  .restaurants-menu-page .info-card {
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .restaurants-menu-page .info-card .img-sec img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {

  /* Site Restaurant Page CSS */
  .restaurant-page .tab-button {
    width: 100%;
    margin-bottom: 1rem;
  }

  .restaurant-page .tab-button button {
    background-color: var(--theme-gray2);
  }
}

@media (max-width: 991px) {
  .modal-full-mobile {
    max-width: 700px;
    width: calc(100% - 30px);
  }

  /* Search Result Page CSS */
  .search-result-page .custom-modal .filter-wrapper .first-block {
    border-bottom: 1px solid var(--theme-gray1);
  }
}

@media (min-width: 576px) {

  /* Restaurant Listing Page CSS */
  .restaurants-menu-page .info-card .info-sec {
    width: calc(100% - 156px);
  }

  .restaurants-menu-page .info-card .img-sec {
    width: 140px;
    height: 138px;
  }
}

@media (min-width: 768px) {

  /* Site Booking Page CSS */
  .booking-form {
    min-height: 1008px;
    border: 17px solid var(--theme-color3);
  }

  /* Site Restaurant Page CSS */
  .restaurant-page .tab-button {
    border-bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (min-width: 992px) {

  /* Booking Page */
  .booking-form {
    margin-top: -55px;
  }

  .divider {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .main-heading {
    font-size: 35px;
    line-height: normal;
  }

  .main-heading1 {
    font-size: 30px;
    line-height: normal;
  }

  .main-heading2 {
    font-size: 24px;
  }

  .fs-md-18 {
    font-size: 18px !important;
  }

  /* Search Result Page CSS */
  .custom-modal .modal-header {
    padding: 12px 25px;
  }

  .custom-modal .modal-header .modal-title {
    font-size: 30px;
  }

  .custom-modal .modal-body {
    color: var(--white);
    padding: 20px 25px 30px;
  }

  .custom-modal .btn-wrapper {
    justify-content: flex-end;
  }

  .meetingPopup .product-details .product-price {
    font-size: 40px;
  }
}

@media (min-width: 1199px) {}

@media (min-width: 1350px) {
  .custom-container {
    width: 1300px;
  }
}

///////// STRIPE CUSTOM FORM ////////////
.stripe-custom-form {
  .stripe-custom-input {
    background-color: #212D63;
    height: 42px;
    font-weight: 600;
    border: 1px solid #efc078;

    &::placeholder {
      color: #abb2bf;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #abb2bf;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #abb2bf;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: #abb2bf;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #abb2bf;
    }
  }

}