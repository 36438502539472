.ourmachines {
  background-image: url("../../../../assets/images/site/MachinesBG.png"); // Replace with your image path
  background-size: cover; /* Ensures the background image covers the entire container */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: 2rem 0;
}

.ourmachine_wrapper {
  .row {
    height: 100%;
  }

  .col-12.col-lg-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 0;
    padding: 20px;
  }
}

.text-content {
  font-size: 17px;
  color: #dadada;
  line-height: 36px;
  font-family: "Poppins";
  margin: 30px 0;
}

#machineheading {
  font-size: 25px;
  margin-bottom: 1rem;
  font-family: Arial Rounded MT Std;
  color: #eead20;
}
