.resigter_wrapper {
  .row {
    height: 100%;
  }
}

#registerheading {
  font-size: 25px;
  margin-bottom: 1rem;
  font-family: Arial Rounded MT Std;
  color: #eead20;
}

#textcont1 {
  font-size: 17px;
  color: #dadada !important;
  line-height: 36px;
  font-family: "Poppins";
  margin: 30px 0;
}

// .text-justify {
//   size: 17px;
//   padding: 10px;
//   font-family: Poppins;
//   color: #ffffff;
// }

#customBtn:hover {
  font-weight: 600;
  transform: scale(1.05);
}

#customBtn {
  width: 100%;
  margin-bottom: 0;
  // size: 17px;
  padding: 10px;
  font-family: Poppins;
  color: #ffffff;
}

//Expansion
.details-section {
  margin-top: 10px;
  padding: 15px;
  font-size: 14px;
  color: #333;
}

.nestedheading {
  font-size: 17px;
  margin-bottom: 1rem;
  font-family: Arial Rounded MT Std;
  color: #eead20;
}

// .text-wrapper {
//   color: #bababa;
//   font-size: 18px;
//   font-family: Poppins;
// }

.feature-wrapper {
  background-color: #111111;
  border: 1px solid #1c1c1c;
}

.feature-wrapper-1 {
  background-color: #1a1a1a;
  border: 1px #272727;
}

.feature-wrapper-2 {
  background-color: #141414;
  border: 1px solid #272727;
  min-height: 80% !important;
}

.smallheading {
  font-family: Poppins;
  font-weight: bold;
}

.restaurant2-wrapper {
  width: 100%;
  height: 30%;
  background-color: #141414;
  border: 1px #1c1c1c;
  padding: 5px;
}

.nightclub2-wrapper {
  width: 100%;
  height: 30%;
  background-color: #141414;
  border: 1px #1c1c1c;
  padding: 5px;
}



#customBtn {
  border-radius: 5px;
}


@media (min-width: 992px) {
  .kioskheading {
    margin-left: 40px; /* Adjust the margin value as needed */
  }
}