input::placeholder {
    font-size: 17px;
    color: #bababa;
  }
  
  
  .menuButton {
      background-color: #EEAD20;
      color: #FFFFFF;
      font-size: 20px;
      font-weight: 600;
      max-width: 432px;
      width: 50%;
      height: 30%;
      padding: 10px;
      font-family: 'Inter', sans-serif;
  }

  #eventform2 {
    margin-bottom: 15px;
    background-color: #191919;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 768px) {
    .eventDetails_wrapper input::placeholder {
      font-size: 14px !important;
    }
    .eventDetails_wrapper input,
  .eventDetails_wrapper textarea {
    padding-left: 30px !important;
  }
  }