.hotelBookin_wrapper {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.hotelBooking {
  // width: 452px;
  max-width: 100%;
  height: 72px;
  background-color: #eead20;
  font-size: 20px;
  font-family: Poppins;
  color: #ffffff;
  margin-left: 20px;
}

#hotelform {
  margin-bottom: 15px;
  background-color: #191919;
  width: 100%;
  // max-width: 452px;
  text-align: center;
}

@media (max-width: 768px) {
  .hotelBooking {
    font-size: 14px;
  }
}

.hotelBookin_wrapper input,
.hotelBookin_wrapper textarea {
  width: 100%;
  height: 72px;
  //   padding: 10px;
  padding-left: 50px !important;
  border-radius: 4px;
  border: 1px solid #282828;
  background-color: #191919;
  color: #bababa;
  font-size: 17px;
}

@media (max-width: 768px) {
  .hotelBookin input::placeholder {
    font-size: 14px !important;
  }
  .hotelBookin input,
.hotelBookin textarea {
  padding-left: 30px !important;
}
}