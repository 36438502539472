.text-content {
    font-size: 17px; 
    color: #dadada;
    line-height: 36px;
    font-family: "Poppins"; 
    margin: 30px 0; 
  }
  
  .vision_wrapper {
    .row {
      height: 100%; 
    }
  
    .col-12.col-lg-6 {
      display: flex; 
      flex-direction: column; 
      justify-content: center; 
      text-align: left; 
      margin-top: 0;
      padding: 20px; 
    }
  
    #visionheading {
      font-size: 25px;
      margin-bottom: 1rem; 
      font-family: Arial Rounded MT Std; 
      color: #EEAD20;
    }
  }
  

  .feature-wrapper {
    background-color: #1D1D1D;
    border: 1px #323232;
  }