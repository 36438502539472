.container-cal {
  width: auto;
  margin: auto;
}

.container-cal .grid td {
  width: 51px;
  height: 47px;
//   padding: 50px 30px;
  border: 2px solid var(--theme-color3);
  text-align: center;
  float: left;
  margin-left: 5px;
  margin-bottom: 5px;
}

.container-cal .grid td:hover {
//   opacity: .7;
  background: var(--theme-color3);
  color: #fff;
  // cursor: pointer;
}

.container-cal .reserved {
  background: var(--theme-color3);
  color: #fff;
  display: inline-block;
}

// .container-cal .reserved::after {
//   content: 'RESERVED'
// }

// .container-cal .available::after {
//   content: 'AVAILABLE'
// }

.container-cal .available {
  color: #333;
}

.container-cal li {
  list-style: none;
}