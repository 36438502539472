.menuSection_wrapper {
  margin-bottom: 80px;
  margin-top: 100px !important;
}

.gradient-line {
  height: 2px; /* Adjust the height as needed */
  width: 100%;
  background: linear-gradient(
    90deg,
    #141414 0%,
    #e36d4b 33.33%,
    #e36d4b 66.67%,
    #141414 100%
  );
  margin-top: 20px;
  margin-bottom: 80 !important;
}

.centered-image {
  position: absolute; /* Stay centered */
  top: 0%; /* Center vertically on the gradient line */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for true center alignment */
  height: 55px; /* Adjust height as necessary */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  z-index: 2; /* Make sure the image is above the contact form */

  @media (max-width: 768px) {
    height: 45px; /* Adjust height for smaller screens */
  }

  @media (max-width: 480px) {
    height: 35px; /* Further adjust for very small screens */
  }
}

.custom-upload-btn {
  display: flex;
  align-items: center;
  background-color: #191919;
  color: #bababa;
  border: 1px solid #282828;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: inherit;
}

.custom-upload-btn:hover {
  border-color: #e36d4b;
}

.menuButton {
  background-color: #eead20;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  max-width: 432px;
  width: 50%;
  height: 30%;
  padding: 10px;
  font-family: "Inter", sans-serif;
}

#menuform {
  margin-bottom: 15px;
  background-color: #191919;
  width: 100%;
  max-width: 452px;
  text-align: center;
}

// #radiohead {
//   background-color: #e36d4b;
//   border-radius: 10px;
//   max-width: 350px;
//   width: 60%;
//   height: 25%;
// }

// #radiobtn1 {
//   margin-top: 30px;
// }

.menuSection_wrapper input,
.menuSection_wrapper textarea {
  width: 100%;
  height: 72px;
  // padding: 10px;
  padding-left: 55px !important;
  border-radius: 4px !important;
  border: 1px solid #282828 !important;
  background-color: #191919 !important;
  color: #bababa;
  font-size: 17px;

}

@media (max-width: 768px) {
  .menuSection_wrapper input::placeholder , .placeholder-text {
    font-size: 14px !important;
  }
  .menuSection_wrapper input,
.menuSection_wrapper textarea {
  padding-left: 45px !important;
}

.placeholder-text {
  padding-left: 0px !important;
}
}




