.goldcoast_wrapper {
  margin-top: 100px;
  margin-bottom: 100px;

  .row {
    height: 100%;
  }

  .col-12.col-lg-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 0;
    padding: 20px;
  }
}

.text-content {
  font-size: 17px;
  color: #dadada;
  line-height: 36px;
  font-family: "Poppins";
  margin: 30px 0;
}

#goldcoastheading {
  font-size: 25px;
  margin-bottom: 1rem;
  font-family: Arial Rounded MT Std;
  color: #eead20;
}

.goldCoastBG {
  position: relative;
  background-image: url("../../../../assets/images/site/surfersparadise-ezgif.com-webp-to-jpg-converter.jpg");
  background-size: cover; /* Ensures the background image covers the entire container */
  background-repeat: no-repeat;
  // min-height: 494px;
  background-position: center;

  // Semi-Transparent Overlay 
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    // z-index: 1; /* Make sure the overlay is behind the content */
  }

  /* Content stays unaffected */
  .content {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    min-height: 300px; /* Reduced minimum height on mobile */
  }
}
