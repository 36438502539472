html,
body {
    background-color: var(--black);
}

/* Start: Global CSS */
// .main-content {
//     padding: 10px 0 0;
// }

.add-another {
    color: var(--theme-color1);
    cursor: pointer;
    font-weight: 500;
}

/* End: Global CSS */

.dining-subscription .stripe-input {
    border: 1px solid #efc078;
    border-radius: 8px;
    padding: 12px;
    background-color: #212D63;
    color: white;
    height: 40px;
    font-size: "12px";
    font-weight: 500;
    font-family: "Sohne, system-ui, sans-serif";
  }


/* Start: Login Page CSS */
.login-page .form-wrapper {
    max-width: 500px;
    background-color: var(--theme-dark1);
    padding: 70px 30px 50px;
    border: 1px solid var(--theme-color2);
    border-radius: 10px;
    margin: 0 auto;
}

.login-page .heading {
    font-size: 32px;
    margin-bottom: 20px;
}

/* End: Login Page CSS */

/* Start: Company Type Page CSS */
.company-list-page {
    background-color: var(--black);
}

.progrss-bar-sec {
    max-height: 80vh;
    overflow: auto;
}
.progrss-bar-sec {
    background-color: var(--theme-dark1);
    box-shadow: -3px -3px 5px var(--theme-gray1);
    border-radius: 16px;
}

.progrss-bar-sec .progress-label {
    color: var(--white);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.progrss-bar-sec .progress-bar-cover {
    background-color: var(--theme-gray1);
    padding: 10px;
    border-radius: 5px;
}

.progrss-bar-sec .progress {
    height: 12px;
    background-color: var(--theme-lightgray);
    border-radius: 30px;
    margin: 0;
}

.progrss-bar-sec .progress-bar {
    background-color: var(--theme-color1);
}

.progrss-bar-sec .menu-wrapper ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.progrss-bar-sec .menu-wrapper>ul>li:not(:last-child) {
    margin-bottom: 12px;
}

.progrss-bar-sec .menu-wrapper>ul>li ul, .progrss-bar-sec .dropdown ul {
    list-style-type: none;
    margin: 12px 0 0 15px;
}

.progrss-bar-sec .menu-wrapper>ul>li>ul li:not(:last-child), .progrss-bar-sec .dropdown ul li:not(:last-child) {
    margin-bottom: 8px;
}

.progrss-bar-sec .menu-wrapper>ul>li>ul>li a {
    font-size: 16px;
}


.progrss-bar-sec .menu-wrapper a {
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    text-decoration: none;
    transition: all .3s;
}

.progrss-bar-sec .menu-wrapper a.active {
    color: var(--theme-color1);
}

.progrss-bar-sec .menu-wrapper a:hover,
.progrss-bar-sec .menu-wrapper a:focus,
.progrss-bar-sec .menu-wrapper a:active {
    color: var(--theme-color1)
}

.progrss-bar-sec .dropdown + .dropdown {
    margin-top: 10px;
}
.progrss-bar-sec .dropdown button, .progrss-bar-sec .dropdown .btn-link {
    color: var(--white);
    font-weight: 600;
    border: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    // text-transform: uppercase;
    width: 100%;
}

.common-info-card {
    border: 4px solid #181a20;
    border-radius: 16px;
    // overflow: hidden;
}

.common-info-card .heading-title {
    font-size: 20px;
    font-weight: 700;
    color: var(--black);
    background-color: var(--theme-color1);
    padding: 12px 16px;
    // line-height: normal;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 1;
}

.common-info-card .details-wrapper {
    padding: 16px 16px 30px;
}

.common-info-card .details-wrapper .sub-title {
    color: var(--white);
    font-size: 20px;
    font-weight: 600;
}

.common-info-card .details-wrapper label {
    color: var(--theme-lightgray);
    font-weight: 500;
}
.common-info-card .details-wrapper label::first-letter {
    text-transform: uppercase;
}

/* End: Company Type Page CSS */

/* Start: Select Booking Calendar Page CSS */
.booking-calendar-page .field-wrapper input {
    color: var(--black);
    background-color: var(--white);
    border-radius: 5px;
    height: 35px;
}

/* End: Select Booking Calendar Page CSS */

/* Start: Complete Activity Page CSS */
.complete-activity-page .info-cover .icon img {
    min-width: 22px;
}

/* End: Complete Activity Page CSS */


/* Start: Location Details Page CSS */
.location-details-page .address-wrapper {
    background-color: var(--theme-lightgray2);
    padding: 10px 16px;
    border: 2px solid #fff;
    border-radius: 6px;
}

.location-details-page .address-wrapper label {
    color: var(--theme-dark1)!important;
}

.location-details-page .address-wrapper .form-control {
    border: 1px solid var(--theme-dark1) !important;
}

/* End: Location Details Page CSS */

/* Start: Ticket Details Pages CSS */
.intensity-level .count {
    height: 40px;
    width: 40px;
    color: var(--black);
    font-size: 20px;
    font-weight: 700;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: var(--white);
    border: 2px solid var(--theme-lightgray2);
    border-radius: 50%;
}

.intensity-level .count.active {
    background-color: var(--theme-color1);
    border-color: var(--white);
}

.custom-field-wrapper .intensity-level .count:not(.active) {
    background-color: var(--theme-lightgray2);
    border-color: var(--white);
}
/* End: Ticket Details Pages CSS */

/* Start: Restaurants Pages CSS */
/* 
.restaurants-page .common-info-card .heading-title {
    font-size: 21px;
} */

.restaurant-details-wrapper:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.restaurants-page .menu-bg-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.restaurants-page .menu-card-wrapper {
    color: var(--black);
    min-height: 650px;
    width: 100%;
    max-width: 75%;
    position: relative;
    z-index: 1;
}

.restaurants-page .menu-card-wrapper .menu-category {
    font-weight: 700;
    text-transform: uppercase;
}

.restaurants-page .btn-preview {
    color: var(--black);
    background-color: var(--theme-lightgray2);
    border: 2px solid var(--white);
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
    text-align: center;
    outline: none !important;
    letter-spacing: 0.5px;
    padding: 6px 40px;
    min-width: 150px;
    transition: all .3s;
    line-height: normal;
}

.restaurants-page .btn-preview:hover,
.restaurants-page .btn-preview:focus,
.restaurants-page .btn-preview:active {
    background-color: var(--theme-color2) !important;
}

/* End: Restaurants Pages CSS */

/* Start: Restaurant Details Page CSS */
.restaurant-details-page .right-section input[type="time"] {
    height: 30px;
}

/* End: Restaurant Details Page CSS */


/* Start: Event Details Pages CSS */
.event-details-page .preview-box {
    background-color: var(--white);
    border: 1px solid var(--white);
    height: 200px;
    min-height: 150px;
    max-width: 200px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.event-details-page .preview-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.event-details-page .moon-wrapper {
    width: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }

.event-details-page .preview-box .rel-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 5px;
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
    color: var(--white);
    text-transform: uppercase;
    line-height: normal;
    width: 100%;
}
.event-details-page .preview-box .rel-text button {
    font-size: 10px;
    color: var(--black);
    background: var(--theme-color3);
    min-height: auto;
    min-width: auto;
    padding: 5px;
    position: relative;
    z-index: 11;
}
.event-details-page .preview-box .rel-text button:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--theme-color3);
    transform-origin: bottom left;
    transform: skew(-16deg, 0deg);
}
/* End: Event Details Pages CSS */

/* Start: Rezdy Integration Pages CSS */
.common-info-card table td, .common-info-card table th {
    background-color: var(--black);
    color: var(--white);
}
.searchfield-wrapper {
    position: relative;
}

.searchfield-wrapper input {
    padding-right: 40px;
}

.searchfield-wrapper img {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 1;
}

.rezdy-integration-page .common-info-card {
    border-color: #4b4d51;
}


// .rezdy-integration-page table>thead>tr>th,
// .rezdy-integration-page table>tbody>tr>td {
//     padding: 12px;
// }

.rezdy-integration-page table>thead>tr>th, .rezdy-integration-page table>tbody>tr>td {
    border-bottom: 0;
}

.rezdy-integration-page table>tbody>tr>td {
    border-top: 3px solid #4b4d51;
}

.rezdy-integration-page table tr > td {
    min-width: 120px;
}
.rezdy-integration-page .price-cover .type {
    min-width: 220px;
    max-width: 230px;
}

.rezdy-integration-page table .btn-search {
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    background-color: var(--theme-color1);
    border: 1px solid var(--white);
    border-radius: 0;
    min-width: 130px;
}

.rezdy-integration-page table .btn-search:hover,
.rezdy-integration-page table .btn-search:active,
.rezdy-integration-page table .btn-search:focus {
    color: #000;
    outline: none;
    box-shadow: none;
}

.meetingPopup .product-img img {
    width: 90px;
    height: 75px;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
}

/* End: Rezdy Integration Pages CSS */

/* Start: New Listing Page CSS */
.common-table-design td .form-control {
    height: 30px;
    padding: 3px;
    font-size: 14px;
}

.common-table-design td .form-control {
    height: 30px;
    padding: 3px;
    font-size: 14px;
}

.common-table-design td button:hover,
.common-table-design td button:focus {
    outline: none !important;
    text-decoration: none;
}

.common-table-design table tr>td {
    vertical-align: middle;
    white-space: nowrap;
}

.new-listing-page td .feedback.form-control {
    color: var(--white);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-style: dotted;
    background-color: transparent;
    box-shadow: none;
}

/* End: New Listing Page CSS */

/* Start: Tags Listing Page CSS */
.tags-listing-page .list-wrapper li:not(:last-child) {
    margin-right: 20px;
    position: relative;
}

.tags-listing-page .list-wrapper li:not(:last-child):before {
    content: '';
    height: 100%;
    width: 1px;
    background-color: var(--white);
    display: block;
    position: absolute;
    right: -13px;
}

/* End: Tags Listing Page CSS */

/* Start: Gain Maximum Exposer Page CSS */
.gain-max-exposer .info-cover table tr td {
    border: 1px solid var(--theme-color1);
}

.gain-max-exposer .info-cover table .label-title {
    font-size: 17px;
    font-weight: 600;
    color: var(--black);
    background-color: var(--theme-color1);
    padding: 5px 16px;
    border-radius: 150px;
    border: 2px solid var(--theme-color1);
}

.label-title-selected {
    // font-size: 17px;
    // font-weight: 600;
    color: var(--black);
    background-color: var(--theme-color1);
    // padding: 5px 16px;
    // border: 2px solid var(--theme-color1);
}

.gain-max-exposer .info-cover table input:checked+.label-title {
    color: var(--white);
    background-color: blue;
    border-width: 2px;
}

.cursor-pointer{
    cursor: pointer;
}
/* End: Gain Maximum Exposer Page CSS */

/* Responsive Css */
@media (min-width: 768px) {

    /* Common info card css */
    .progrss-bar-sec {
        min-height: 400px;
    }

    /* Rezdy Integration Pages CSS */
    .rezdy-integration-page table>thead>tr>th:first-child,
    .rezdy-integration-page table>tbody>tr>td:first-child {
        padding-left: 0;
    }

    .rezdy-integration-page table>thead>tr>th:last-child,
    .rezdy-integration-page table>tbody>tr>td:last-child {
        padding-right: 0;
    }

    /* Start: Tags Listing Page CSS*/
    .tags-listing-page ul.list-wrapper {
        margin-left: 3px;
    }

    .tags-listing-page .list-wrapper li:not(:last-child) {
        margin-right: 50px;
    }

    .tags-listing-page .list-wrapper li:not(:last-child):before {
        right: -28px;
    }

    .common-info-card .heading-title {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 992px) {

    /* Common info card css */
    .common-info-card {
        border: 7px solid #181a20;
    }

    .common-info-card .heading-title {
        font-size: 24px;
    }

    .common-info-card .details-wrapper {
        padding: 16px 20px 30px;
    }

    .common-info-card .details-wrapper .sub-title {
        font-size: 24px;
    }

    /* Select Booking Calendar Page CSS */
    .booking-calendar-page .field-wrapper .row {
        display: flex;
        align-items: center;
    }

    .booking-calendar-page .field-wrapper label {
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {

    /* Common info card css */
    .common-info-card .heading-title {
        font-size: 30px;
    }

    /* Start: Tags Listing Page CSS*/
    .tags-listing-page .list-wrapper li:not(:last-child) {
        margin-right: 70px;
    }

    .tags-listing-page .list-wrapper li:not(:last-child):before {
        right: -38px;
    }
}

@media (min-width: 1400px) {}