.whyChooseKiosk {
  background-color: #111111;
  border: 1px solid #1c1c1c;
}

.row2 {
  background-image: url("../../../../assets/images/site/HotelKioskBG.png"); // Replace with your image path
  background-size: cover; /* Ensures the background image covers the entire container */
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 236px;
  padding: 2rem 0;
  display: flex; /* Enable flexbox */
  flex-wrap: wrap; /* Allow items to wrap if necessary */
}

.text-content2 {
    font-size: 27px;
    font-family: Poppins;
    color: #E4E4E4;
}

.kioskbutton {
    width: 452px;
    height: 72px;
    border-radius: 0;
    background-color: #EEAD20;
    color: #FFFFFF;
    font-size: 20px;
    
}


.small-logo {
    width: 41px; /* Adjust the size as needed */
    height: 14px; /* Maintain aspect ratio */
    margin-bottom: 8px; /* Add some space between the logos */
  }
  
  .gradient-line {
    height: 2px; /* Adjust the height as needed */
    width: 100%;
    background: linear-gradient(90deg, #141414 0%, #EEAD20 33.33%, #EEAD20 66.67%, #141414 100%);
    margin-top: 20px; 
    margin-bottom: 80px !important;
  }
  

