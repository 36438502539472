// .banner2 .banner2-txt, .banner2 .item > img {height: 600px;}
.banner2 {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.banner2 .col-lg-4 {
  padding-right: 0;
}

.banner2 .col-lg-8 {
  padding-left: 0;
}

.banner2 .container-fluid {
  padding: 0;
}

.banner2 .item > img {
  width: 100%;
  object-fit: cover;
  object-position: right;
}

.banner2 .banner2-txt,
.banner2 .item > img {
  height: 600px;
}

.banner2-txt {
  position: relative;
  padding: 0 0 0 20em;
  width: 750px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgb(250, 201, 22);
  flex-direction: column;
  margin: 0;
}

.banner2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 812px;
}
.banner2 .col-lg-4 {
  padding-right: 0;
}
.banner2 .col-lg-8 {
  padding-left: 0;
}
.banner2 .container-fluid {
  padding: 0;
}
.banner2 .item > img {
  width: 100%;
  object-fit: cover;
  object-position: right;
}
.banner2 .banner2-txt,
.banner2 .item > img {
  height: 812px;
}
.banner2-txt {
  position: relative;
  padding: 0 0 0 20em;
  width: 750px;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgb(250, 201, 22);
  flex-direction: column;
  margin: 0;
}
.banner2-txt::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  background-image: url("../../../../assets/images/banner-cover.jpg");
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  z-index: 2;
}

#bannerBackground {
  background-image: url("../../../../assets/images/site/BackgroundBanner.png");
  background-size: cover;
  height: 600px;
  width: 100%;
}

.banner2-txt::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 120%;
  background-color: rgb(250, 201, 22);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  mix-blend-mode: multiply;
  z-index: 2;
}

.banner2-txt h2 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 900;
  margin: 0 0 6px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: uppercase;
  position: relative;
  z-index: 4;
  transition: all 0.2s ease;
}

.banner2-txt p {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  z-index: 4;
  border-radius: 3px;
  letter-spacing: 2px;
  margin: 0 0 1rem;
}

#bannerheading {
  margin-top: 30px;
  font-size: 57px;
  color: #ffffff;
  font-weight: bold;
  font-family: Poppins;
  z-index: 10; /* Ensure it appears above other elements */
}

#bannerheading::before {
  content: "";
  position: absolute;
  top: -10%;
  left: 80%;
  transform: translateX(-50%);
  background-image: url("../../../../assets/images/site/curve\ 2.png");
  background-size: 68px;
  width: 68px;
  height: 18px;
}

#bannerheading::after {
  content: "";
  position: absolute;
  top: 50%; /* Adjust this value to move the logo up or down */
  left: 80%; /* Adjust this to move the logo left or right */
  transform: translate(-50%, 0); /* Adjust as necessary */
  background-image: url("../../../../assets/images/site/HAND 1.png");
  background-size: 50px;
  width: 50px;
  height: 57px;
}

.bannerbutton {
  z-index: 10;
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  max-width: 218px;
  height: 58px;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
}

@media (min-width: 1200px) {
  /* Adjust the min-width value as needed to target your definition of large screens */
  .static-image-container {
    margin-right: -100px !important;
    margin-left: 100px !important;
  }
  #bannerheading {
  	left: 10%;
  }
  .bannertext1 {
  	margin-left: 55px !important;
  	font-size: 27px;
  }

  .bannerbutton {
  	margin-left: 55px !important;
  }
}

// Diplay none on mobile screens
@media (max-width: 768px) {
  .static-image-container {
    text-align: center; /* Keep it centered on mobile */
    margin-top: 1rem; /* Adjust margin for mobile */
  }

  .static-image-container img {
    max-width: 100%; /* Make image responsive */
    height: 300px; /* Maintain aspect ratio */
  }

  #bannerBackground {
    background-size: cover; /* Adjusts the image to fit within the container */
    background-position: top; /* Aligns the image to the top */
    height: 400px; /* Reduce the height for a better fit on mobile */
  }
}

@media (min-width: 768px) {
  .maskot-footer {
    position: absolute;
    left: 22px;
    top: -17px;
    width: 150px;
    filter: drop-shadow(4px 6px 6px rgba(0, 0, 0, 0.25));
    z-index: 20;
  }

  .banner2-txt {
    padding-bottom: 40px !important;
  }
  #bannerheading {
    font-size: 57px !important;
  }
  #register2ndtext {
    font-size: 26px !important;
  }
  #registerlasttext {
    font-size: 16px !important;
  }

  #bannerheading::after {
    content: "";

    top: 50% !important; /* Adjust this value to move the logo up or down */
    left: 80%; /* Adjust this to move the logo left or right */
    background-size: 40px;
    width: 40px !important;
    height: 47px !important;
  }

  #bannerheading::before {
    content: "";

    top: -5% !important;
    left: 80%;
    transform: translateX(-50%);
    background-image: url("../../../../assets/images/site/curve\ 2.png");
    background-size: 60px;
    width: 60px;
    height: 17px;
  }
}

@media (max-width: 1024px) and (max-height: 600px) {
  #register2ndtext {
    font-size: 2px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #registerlasttext {
    font-size: 15px !important;
  }
}

@media (min-width: 1200px) {
  // .heading {
  // 	font-size: 2.5rem;
  // }

  .footer-menu li a {
    font-size: 1.3rem;
  }
}

@media (max-width: 1886px) {
  .banner2-txt {
    padding: 0 0 0 3em;
    width: 118%;
  }

  /* .custom-carousel-wrapper .carousel-indicators {left: 0;transform: translateX(-130px);} */
}

@media (max-width: 1400px) {
  // .banner2-txt p { letter-spacing: 2px;}
  /* .custom-carousel-wrapper .carousel-indicators {left: 0;transform: translateX(-90px);} */
  // .keypoints .container, .profile .container { width: inherit;}
  .top-deals .util-carousel {
    margin: 1.5em 0 0;
  }

  // .te .heading, .top-deals .heading, .testimonials .heading { margin-left: 15px;}
}

@media (max-width: 1199px) {
  .join-blocks-img img {
    height: 100%;
    object-fit: cover;
  }

  .footer-menu .heading {
    font-size: 2em;
  }

  .top-deals .util-carousel {
    margin: 1.5em 0 0;
  }

  .take-me-there .join-blocks h4 {
    font-size: 16px;
  }

  #cssmenu {
    transform: translateX(70px);
  }

  #cssmenu > ul > li > a {
    padding: 0 20px;
  }

  .landing-page #cssmenu > ul > li > a {
    padding: 0 45px;
  }

  .banner2-txt p {
    letter-spacing: 1px;
  }

  .slider-controls {
    margin: 0.5em 0 0;
  }

  .experiences::after {
    width: 55%;
    right: -33%;
  }

  #carousel div.selected {
    width: 600px;
  }
}

@media (max-width: 991px) {
  .join-blocks a {
    font-size: 1em;
  }

  #cssmenu {
    transform: translateX(0);
  }

  #cssmenu #menu-button:before {
    top: 17px;
    right: 17px;
  }

  #cssmenu #menu-button:after {
    top: 23px;
    right: 17px;
  }

  #cssmenu ul {
    margin: 0;
    transform: translateY(104px);
  }

  #cssmenu ul li {
    border-top: 0;
  }

  #cssmenu > ul > li > a,
  #cssmenu > ul > li:last-child > a {
    padding: 15px 20px;
    background-color: #000;
  }

  #cssmenu #menu-button:after {
    border-width: 2px;
    right: auto;
    left: 17px;
    top: 16px;
    border-color: #fff;
    transition: none;
  }

  #cssmenu #menu-button:before {
    height: 2px;
    right: auto;
    left: 17px;
    top: 10px;
    background-color: #fff;
    transition: none;
  }

  #cssmenu #menu-button.menu-opened:before {
    top: 85px;
    background: #fff;
  }

  #cssmenu #menu-button.menu-opened:after {
    top: 85px;
    background: #fff;
    height: 2px;
  }

  .landing-page #cssmenu ul {
    display: block;
  }

  .landing-page #cssmenu ul {
    transform: translateY(10px);
  }

  .landing-page #cssmenu > ul > li > a,
  .landing-page #cssmenu > ul > li:last-child > a {
    padding: 15px 20px;
  }

  .landing-page #cssmenu #menu-button.menu-opened:before {
    top: 10px;
  }

  .landing-page #cssmenu #menu-button.menu-opened:after {
    top: 10px;
  }

  .landing-image > img {
    margin: 0 auto;
  }

  .landing .large {
    margin: 6em auto 0;
  }

  .experiences .join-blocks h4 {
    font-size: 18px;
  }

  .maskot {
    top: 27%;
  }

  .banner2-txt {
    width: 100%;
  }

  .banner2-txt::before,
  .banner2-txt::after {
    width: 390px;
  }

  .banner2-txt h2 {
    font-size: 2em !important;
  }

  .banner2-txt h2,
  .banner2-txt p {
    white-space: nowrap;
  }

  .banner2-txt h2 {
    font-size: 2em !important;
  }

  .profile .col-lg-9,
  .profile .col-lg-3 {
    width: 100%;
  }

  .reservation-form .persons {
    width: 100%;
  }

  .reservation-form {
    height: auto;
  }

  /* .custom-carousel-wrapper .carousel-indicators {transform: translateX(-55px);} */
  .experiences::after {
    right: -53%;
  }

  #carousel div.selected img {
    width: 600px;
  }

  .buttons button:first-child {
    left: 12%;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0.3em;
    border-radius: 6px;
  }

  .buttons button:last-child {
    right: 12%;
    z-index: 21;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0.3em;
    border-radius: 6px;
  }
}
@media (max-width: 767px) {
  .slider-controls {
    margin: 0;
    display: none;
  }
  .banner2-txt {
    text-align: center;
    background-color: #fac532;
    height: auto !important;
    padding: 1.5em 0;
    align-items: center;
    margin: 0 auto;
    z-index: 1;
  }
  .banner2-txt h2 {
    font-size: 2em;
    letter-spacing: 1px;
    margin: 0;
  }
  .banner2-txt p {
    font-size: 26px;
    margin: 0 0 0.4em;
  }
  .banner2-txt h2,
  .banner2-txt p {
    white-space: normal;
  }
  .banner2-txt::before,
  .banner2-txt::after {
    width: 100%;
  }
  .banner2 .item > img {
    height: 320px !important;
  }
  .book-now {
    padding: 0.5em 1em;
    font-size: 16px;
    letter-spacing: 1px;
    color: #ffffff;
  }
  .custom-carousel-wrapper .carousel-control {
    display: flex;
  }
  .wavey.desk {
    display: none;
  }
  .wavey.mob {
    margin: auto;
    display: block;
    right: 0;
    height: auto;
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  .custom-carousel-wrapper .carousel-control {
    transform: translateY(-50%);
    top: 50%;
  }
  .custom-carousel-wrapper .left.carousel-control .img-responsive {
    left: 30px;
    width: 30px;
  }
  .custom-carousel-wrapper .right.carousel-control .img-responsive {
    right: 30px;
    width: 30px;
  }
  .top-experiences .util-theme-default .util-prev img,
  .top-experiences .util-theme-default .util-next img {
    width: 20px;
  }
  /* .keypoints .keypoints-container { margin: 0 0 1.5em;} */
  /* .keypoints-bg .col-xs-6:nth-child(3) .keypoints-container, .keypoints-bg .col-xs-6:last-child .keypoints-container { margin: 0;} */
  .keypoints .keypoints-container::before {
    left: 50%;
    top: 2px;
    width: 40px;
    height: 22px;
    transform: translateX(-50%);
  }

  footer {
    padding: 40px 0 40px;
  }

  footer .footer-logo img {
    max-width: 250px;
  }
}
