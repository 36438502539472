.feature-wrapper {
  background-color: #111111;
  border: 1px solid #1c1c1c;
}

.steptwo_wrapper {
  
  font-weight: 400;
  display: flex;
  justify-content: center;

  #stepTwoheading {
    font-size: 25px;
    text-align: center;
    color: #eead20;
    margin-bottom: 1rem;
    font-family: Arial Rounded MT Std;
  }

  .text-content {
    font-size: 17px;
    line-height: 36px;
    color: #bababa;
    text-align: center; /* Default for small screens */
    margin-bottom: 1.5rem; /* Consistent spacing below text */
    font-family: "Poppins";

    /* Media query for larger screens */
    @media (min-width: 768px) {
      text-align: left;
    }
  }
}

.content-container {
  max-width: 1000px; /* Ensures the content aligns with the video width */
  width: 100%;
  padding: 16px;
  margin: 0 auto; 
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto; /* Center the button horizontally */
  padding: 0.5rem 1.5rem;
  font-size: 17px;
  background-color: #191919;
  color: #bababa;
  border: 1px solid #282828;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;

  span {
    color: #eead20; /* Specific color for "Rezdy" span text */
  }

  img {
    margin-left: 8px; /* Adds spacing between text and icon */
  }
}

.video-container {
  max-width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem; 
  // background: linear-gradient(180deg, #ee8351 0%, #d24c42 100%);
  border-radius: 30px;

  video {
    width: 100%;
    max-width: 1000px;
    height: 524px;
    border: 1px solid #282828;
    border-radius: 30px;
  }
}

/* For smaller screens, adjust width and height */
@media (max-width: 768px) {
  .video-container video {
    height: auto;
  }

  .text-content {
    line-height: 1.5;
    color: #bababa;
  }
}
